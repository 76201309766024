import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import moment from 'moment';
import { ServiceType } from '~/constants/common';
import {
  CreateAgeGlycationInput,
  CreateBloodGlucoseInput,
  CreateBloodPressureInput,
  CreateCapillaryTypeInput,
  CreateExerciseManagementInput,
  CreateGripStrengthInput,
  CreatePulseWaveInput,
  PaginationInput,
  PaginationPageInfo,
  UserServiceRecordInput,
} from '~/models/common';
import {
  createAgeGlycation,
  createBloodGlucose,
  createBloodPressure,
  createCapillaryType,
  createCustomService,
  createExerciseManagement,
  createGripStrength,
  createPulseWave,
  deleteHistory,
  getHistoryAgeGlycation,
  getHistoryBloodGlucose,
  getHistoryBloodPressure,
  getHistoryCalorie,
  getHistoryCapillaryType,
  getHistoryCustomService,
  getHistoryExercise,
  getHistoryExerciseManagement,
  getHistoryFatigue,
  getHistoryGripStrength,
  getHistoryHeartRate,
  getHistoryPulseWave,
  getHistoryRunning,
  getHistorySleep,
  getHistoryStand,
  getHistoryStep,
  getHistoryWalking,
} from '~/services/historyService';
import { thunkAction } from '~/utils/utils';

const initialState = {
  history: {
    data: [],
    pagination: {} as PaginationPageInfo,
    totalDistance: null,
    totalTime: null,
  } as any,
  error: {},
  loading: false,
};

export const fetchHistoryExerciseManagement = createAsyncThunk(
  'history/historyExerciseManagement',
  thunkAction(
    async (payload: { userId: string; pagination: PaginationInput }) => {
      const res = await getHistoryExerciseManagement({
        userId: payload.userId,
        pagination: payload.pagination,
      });
      return res?.data?.historyExerciseManagement;
    },
  ),
);

export const fetchHistoryAgeGlycation = createAsyncThunk(
  'history/historyAgeGlycation',
  thunkAction(
    async (payload: { userId: string; pagination: PaginationInput }) => {
      const res = await getHistoryAgeGlycation({
        userId: payload.userId,
        pagination: payload.pagination,
      });
      return res?.data?.historyAgeGlycation;
    },
  ),
);

export const fetchHistoryBloodGlucose = createAsyncThunk(
  'history/historyBloodGlucose',
  thunkAction(
    async (payload: { userId: string; pagination: PaginationInput }) => {
      const res = await getHistoryBloodGlucose({
        userId: payload.userId,
        pagination: payload.pagination,
      });
      return res?.data?.historyBloodGlucose;
    },
  ),
);

export const fetchHistoryPulseWave = createAsyncThunk(
  'history/historyPulseWave',
  thunkAction(
    async (payload: { userId: string; pagination: PaginationInput }) => {
      const res = await getHistoryPulseWave({
        userId: payload.userId,
        pagination: payload.pagination,
      });
      return res?.data?.historyPulseWave;
    },
  ),
);

export const fetchHistoryGripStrength = createAsyncThunk(
  'history/historyGripStrength',
  thunkAction(
    async (payload: { userId: string; pagination: PaginationInput }) => {
      const res = await getHistoryGripStrength({
        userId: payload.userId,
        pagination: payload.pagination,
      });
      return res?.data?.historyGripStrength;
    },
  ),
);

export const fetchHistoryBloodPressure = createAsyncThunk(
  'history/historyBloodPressure',
  thunkAction(
    async (payload: { userId: string; pagination: PaginationInput }) => {
      const res = await getHistoryBloodPressure({
        userId: payload.userId,
        pagination: payload.pagination,
      });
      return res?.data?.historyBloodPressure;
    },
  ),
);

export const fetchHistoryCapillaryType = createAsyncThunk(
  'history/historyCapillaryType',
  thunkAction(
    async (payload: { userId: string; pagination: PaginationInput }) => {
      const res = await getHistoryCapillaryType({
        userId: payload.userId,
        pagination: payload.pagination,
      });
      return res?.data?.historyCapillaryType;
    },
  ),
);

export const fetchHistoryCustomService = createAsyncThunk(
  'history/historyCustomService',
  thunkAction(
    async (payload: {
      serviceId: string;
      userId: string;
      pagination: PaginationInput;
    }) => {
      if (
        payload.serviceId === ServiceType.HeartRate ||
        payload.serviceId === ServiceType.Sleep ||
        payload.serviceId === ServiceType.Step ||
        payload.serviceId === ServiceType.Exercise ||
        payload.serviceId === ServiceType.Calorie ||
        payload.serviceId === ServiceType.Stand ||
        payload.serviceId === ServiceType.Walking ||
        payload.serviceId === ServiceType.Running ||
        payload.serviceId === ServiceType.Fatigue
      ) {
        return {
          data: Array.from({ length: 30 }).map(() => ({
            value: Math.floor(Math.random() * 100),
            minValue: Math.floor(Math.random() * 100),
            maxValue: Math.floor(Math.random() * 100),
            steps: Math.floor(Math.random() * 100),
            totalPerDay: Math.floor(Math.random() * 100),
            recordedAt: moment().toISOString(),
          })),
        };
      }

      const res = await getHistoryCustomService({
        serviceId: payload.serviceId,
        userId: payload.userId,
        pagination: payload.pagination,
      });

      return res?.data?.historyUserServicesRecord;
    },
  ),
);

export const fetchHistoryHeartRate = createAsyncThunk(
  'history/historyHeartRate',
  thunkAction(
    async (payload: { userId: string; from: any; to: any; mode?: any }) => {
      const res = await getHistoryHeartRate({
        userId: payload.userId,
        from: payload.from,
        to: payload.to,
        mode: payload.mode,
      });
      return res?.data?.historyHeartRate;
    },
  ),
);

export const fetchHistoryStep = createAsyncThunk(
  'history/historyStep',
  thunkAction(
    async (payload: { userId: string; from: any; to: any; mode?: any }) => {
      const res = await getHistoryStep({
        userId: payload.userId,
        from: payload.from,
        to: payload.to,
        mode: payload.mode,
      });
      return res?.data?.historyStep;
    },
  ),
);

export const fetchHistoryExercise = createAsyncThunk(
  'history/historyExercise',
  thunkAction(
    async (payload: { userId: string; from: any; to: any; mode?: any }) => {
      const res = await getHistoryExercise({
        userId: payload.userId,
        from: payload.from,
        to: payload.to,
        mode: payload.mode,
      });
      return res?.data?.historyExercise;
    },
  ),
);

export const fetchHistoryCalorie = createAsyncThunk(
  'history/historyCalorie',
  thunkAction(
    async (payload: { userId: string; from: any; to: any; mode?: any }) => {
      const res = await getHistoryCalorie({
        userId: payload.userId,
        from: payload.from,
        to: payload.to,
        mode: payload.mode,
      });
      return res?.data?.historyCalorie;
    },
  ),
);

export const fetchHistoryStand = createAsyncThunk(
  'history/historyStand',
  thunkAction(
    async (payload: { userId: string; from: any; to: any; mode?: any }) => {
      const res = await getHistoryStand({
        userId: payload.userId,
        from: payload.from,
        to: payload.to,
        mode: payload.mode,
      });

      const data = res?.data?.historyStand || {
        data: [],
      };

      return {
        ...data,
        data: data?.data?.filter((item: any) => item.value !== null),
      };
    },
  ),
);

export const fetchHistoryFatigue = createAsyncThunk(
  'history/historyFatigue',
  thunkAction(async (payload: { userId: string; pagination: any }) => {
    const res = await getHistoryFatigue({
      userId: payload.userId,
      pagination: payload.pagination,
    });
    return res?.data?.historyFatigue;
  }),
);

export const fetchHistoryRunning = createAsyncThunk(
  'history/historyRunning',
  thunkAction(
    async (payload: {
      userId: string;
      pagination: any;
      from: any;
      to: any;
    }) => {
      const res = await getHistoryRunning({
        userId: payload.userId,
        pagination: payload.pagination,
        from: payload.from,
        to: payload.to,
      });
      return res?.data?.historyRunning;
    },
  ),
);

export const fetchHistoryWalking = createAsyncThunk(
  'history/historyWalking',
  thunkAction(
    async (payload: {
      userId: string;
      pagination: any;
      from: any;
      to: any;
    }) => {
      const res = await getHistoryWalking({
        userId: payload.userId,
        pagination: payload.pagination,
        from: payload.from,
        to: payload.to,
      });
      return res?.data?.historyWalking;
    },
  ),
);

export const fetchHistorySleep = createAsyncThunk(
  'history/historySleep',
  thunkAction(async (payload: { userId: string; from: any; to: any }) => {
    const res = await getHistorySleep({
      userId: payload.userId,
      from: payload.from,
      to: payload.to,
    });
    return res?.data?.historySleep;
  }),
);

export const postDeleteHistory = createAsyncThunk(
  'history/deleteHistory',
  thunkAction(async (payload: { id: string; type: ServiceType }) => {
    return deleteHistory(payload.type, payload.id);
  }),
);

export const postCreateExerciseManagement = createAsyncThunk(
  'history/createExerciseManagement',
  thunkAction(async (payload: CreateExerciseManagementInput) => {
    return createExerciseManagement(payload);
  }),
);

export const postCreateAgeGlycation = createAsyncThunk(
  'history/createAgeGlycation',
  thunkAction(async (payload: CreateAgeGlycationInput) => {
    return createAgeGlycation(payload);
  }),
);

export const postCreateBloodGlucose = createAsyncThunk(
  'history/createBloodGlucose',
  thunkAction(async (payload: CreateBloodGlucoseInput) => {
    return createBloodGlucose(payload);
  }),
);

export const postCreatePulseWave = createAsyncThunk(
  'history/createPulseWave',
  thunkAction(async (payload: CreatePulseWaveInput) => {
    return createPulseWave(payload);
  }),
);

export const postCreateGripStrength = createAsyncThunk(
  'history/createGripStrength',
  thunkAction(async (payload: CreateGripStrengthInput) => {
    return createGripStrength(payload);
  }),
);

export const postCreateBloodPressure = createAsyncThunk(
  'history/createBloodPressure',
  thunkAction(async (payload: CreateBloodPressureInput) => {
    return createBloodPressure(payload);
  }),
);

export const postCreateCapillaryType = createAsyncThunk(
  'history/createCapillaryType',
  thunkAction(async (payload: CreateCapillaryTypeInput) => {
    return createCapillaryType(payload);
  }),
);

export const postCreateCustomService = createAsyncThunk(
  'history/createCustomService',
  thunkAction(async (payload: UserServiceRecordInput) => {
    return createCustomService(payload);
  }),
);

const HistorySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(postDeleteHistory.fulfilled, (state) => ({
      ...state,
      error: {},
      loading: false,
    }));

    builder.addMatcher(
      isAnyOf(
        fetchHistoryExerciseManagement.fulfilled,
        fetchHistoryAgeGlycation.fulfilled,
        fetchHistoryBloodGlucose.fulfilled,
        fetchHistoryPulseWave.fulfilled,
        fetchHistoryGripStrength.fulfilled,
        fetchHistoryBloodPressure.fulfilled,
        fetchHistoryCapillaryType.fulfilled,
        fetchHistoryCustomService.fulfilled,
        fetchHistoryHeartRate.fulfilled,
        fetchHistoryStep.fulfilled,
        fetchHistoryExercise.fulfilled,
        fetchHistoryCalorie.fulfilled,
        fetchHistoryStand.fulfilled,
        fetchHistoryFatigue.fulfilled,
        fetchHistoryRunning.fulfilled,
        fetchHistoryWalking.fulfilled,
        fetchHistorySleep.fulfilled,
      ),
      (state, action) => {
        return {
          ...state,
          history: {
            data: [...action.payload.data],
            pagination: { ...action.payload.pagination },
            totalDistance: action.payload?.totalDistance,
            totalTime: action.payload?.totalTime,
          },
          error: {},
          loading: false,
        };
      },
    );

    builder.addMatcher(
      isAnyOf(
        postCreateExerciseManagement.fulfilled,
        postCreateAgeGlycation.fulfilled,
        postCreateBloodGlucose.fulfilled,
        postCreatePulseWave.fulfilled,
        postCreateGripStrength.fulfilled,
        postCreateBloodPressure.fulfilled,
        postCreateCapillaryType.fulfilled,
        postCreateCustomService.fulfilled,
      ),
      (state) => ({
        ...state,
        error: {},
        loading: false,
      }),
    );

    builder.addMatcher(
      isAnyOf(
        fetchHistoryExerciseManagement.pending,
        fetchHistoryAgeGlycation.pending,
        fetchHistoryBloodGlucose.pending,
        fetchHistoryPulseWave.pending,
        fetchHistoryGripStrength.pending,
        fetchHistoryBloodPressure.pending,
        fetchHistoryCapillaryType.pending,
        postDeleteHistory.pending,
        postCreateExerciseManagement.pending,
        postCreateAgeGlycation.pending,
        postCreateBloodGlucose.pending,
        postCreatePulseWave.pending,
        postCreateGripStrength.pending,
        postCreateBloodPressure.pending,
        postCreateCapillaryType.pending,
        fetchHistoryCustomService.pending,
        postCreateCustomService.pending,
        fetchHistoryHeartRate.pending,
        fetchHistoryStep.pending,
        fetchHistoryExercise.pending,
        fetchHistoryCalorie.pending,
        fetchHistoryStand.pending,
        fetchHistoryFatigue.pending,
        fetchHistoryRunning.pending,
        fetchHistoryWalking.pending,
        fetchHistorySleep.pending,
      ),
      (state) => ({
        ...state,
        history: {
          data: [],
          pagination: {},
        },
        loading: true,
      }),
    );
    builder.addMatcher(
      isAnyOf(
        fetchHistoryExerciseManagement.rejected,
        fetchHistoryBloodGlucose.rejected,
        fetchHistoryPulseWave.rejected,
        fetchHistoryGripStrength.rejected,
        fetchHistoryBloodPressure.rejected,
        fetchHistoryCapillaryType.rejected,
        postDeleteHistory.rejected,
        postCreateExerciseManagement.rejected,
        postCreateAgeGlycation.rejected,
        postCreateBloodGlucose.rejected,
        postCreatePulseWave.rejected,
        postCreateGripStrength.rejected,
        postCreateBloodPressure.rejected,
        postCreateCapillaryType.rejected,
        fetchHistoryCustomService.rejected,
        postCreateCustomService.rejected,
        fetchHistoryHeartRate.rejected,
        fetchHistoryStep.rejected,
        fetchHistoryExercise.rejected,
        fetchHistoryCalorie.rejected,
        fetchHistoryStand.rejected,
        fetchHistoryFatigue.rejected,
        fetchHistoryRunning.rejected,
        fetchHistoryWalking.rejected,
        fetchHistorySleep.rejected,
      ),
      (state, action) => ({
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      }),
    );
  },
});

export default HistorySlice.reducer;
