import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  changePassword,
  sendMailForgotPassword,
} from '~/services/forgotPasswordService';
import { thunkAction } from '~/utils/utils';

const initialState = {
  email: null,
  error: {},
  loading: false,
};

export const sendEmailForgotPassword = createAsyncThunk(
  'auth/sendMailForgotPassword',
  thunkAction((email: string) => {
    return sendMailForgotPassword(email);
  }),
);

export const changeForgotPassword = createAsyncThunk(
  'auth/changeForgotPassword',
  thunkAction(
    (payload: {
      newPassword: string;
      confirmPassword: string;
      token: string;
    }) => {
      return changePassword(
        payload.newPassword,
        payload.confirmPassword,
        payload.token,
      );
    },
  ),
);

const ForgotPasswordSlice = createSlice({
  name: 'auth/forgot-password',
  initialState,
  reducers: {
    setEmail: (state, action) => {
      return {
        ...state,
        email: action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(sendEmailForgotPassword.fulfilled, (state) => {
      return {
        ...state,
        error: {},
        loading: false,
      };
    });
    builder.addCase(changeForgotPassword.fulfilled, (state) => {
      return {
        ...state,
        error: {},
        loading: false,
      };
    });
    builder.addMatcher(
      isAnyOf(sendEmailForgotPassword.pending, changeForgotPassword.pending),
      (state) => ({
        ...state,
        loading: true,
      }),
    );
    builder.addMatcher(
      isAnyOf(sendEmailForgotPassword.rejected, changeForgotPassword.rejected),
      (state, action) => ({
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      }),
    );
  },
});

export const { setEmail } = ForgotPasswordSlice.actions;
export default ForgotPasswordSlice.reducer;
