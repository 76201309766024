import { apolloClient } from '~/apollo';
import { DEFAULT_PER_PAGE } from '~/constants/common';
import { CREATE_MEMBER, UPDATE_MEMBER } from '~/graphql/mutations';
import { LIST_COMPANY_MEMBER } from '~/graphql/queries';
import { CompanyUpdateMemberInput } from '~/models/common';

export const createMember = async (
  name: string,
  email: string,
  authority: string,
): Promise<any> => {
  return apolloClient.mutate({
    mutation: CREATE_MEMBER,
    variables: { data: { name, email, authority } },
  });
};

export const listMember = async ({ companyId, pagination }): Promise<any> => {
  return apolloClient.query({
    query: LIST_COMPANY_MEMBER,
    variables: {
      where: {
        companyId: {
          equals: companyId,
        },
      },
      pagination: {
        page: pagination?.page || 1,
        perPage: DEFAULT_PER_PAGE,
      },
    },
  });
};

export const updateMember = async (
  payload: CompanyUpdateMemberInput,
): Promise<any> => {
  return apolloClient.mutate({
    mutation: UPDATE_MEMBER,
    variables: { data: payload },
  });
};
