const PATH = {
  system: {
    login: '/system/login',
    member: {
      list: '/system/member',
      edit: '/system/member/:id',
    },
    company: {
      list: '/system/company',
      edit: (id: string = ':id'): string => `/system/company/edit/${id}`,
      create: '/system/company/create',
      detail: (id: string = ':id'): string => `/system/company/detail/${id}`,
    },
    user: {
      list: '/system/user',
      detail: (id: string = ':id'): string => `/system/user/detail/${id}`,
      edit: (id: string = ':id'): string => `/system/user/edit/${id}`,
    },
    notification: {
      company: {
        list: '/system/notification',
        create: '/system/notification/create',
        detail: (id: string = ':id'): string =>
          `/system/notification/detail/${id}`,
        edit: (id: string = ':id'): string => `/system/notification/edit/${id}`,
      },
      user: {
        list: '/system/notification-user',
        create: '/system/notification-user/create',
        detail: (id: string = ':id'): string =>
          `/system/notification-user/detail/${id}`,
        edit: (id: string = ':id'): string =>
          `/system/notification-user/edit/${id}`,
      },
    },
    service: {
      list: '/system/service',
      create: '/system/service/create',
      detail: (id: string = ':id'): string => `/system/service/detail/${id}`,
      edit: (id: string = ':id'): string => `/system/service/edit/${id}`,
    },
    questionnaire: {
      list: '/system/questionnaire/list',
      create: '/system/questionnaire/create',
      detail: (id: string = ':id'): string => `/system/questionnaire/${id}`,
      edit: (id: string = ':id'): string => `/system/questionnaire/edit/${id}`,
    },
    healthCheck: {
      list: '/system/health-check/list',
      listUserAnswer: (questionnaireId: string = ':questionnaireId'): string =>
        `/system/health-check/list-users-answer/${questionnaireId}`,
      userAnswerDetail: (
        id: string = ':questionnaireId',
        userId: string = ':userId',
      ): string => `/system/health-check/${id}/answer-detail/${userId}`,
      create: '/system/health-check/create',
      edit: (id: string = ':id'): string => `/system/health-check/edit/${id}`,
    },
  },
  company: {
    login: '/company/login',
    setting: {
      index: '/company/setting',
      updateCompany: '/company/setting/edit',
      department: {
        list: '/company/setting/department',
        create: '/company/setting/department/create',
        edit: (id: string = ':id'): string =>
          `/company/setting/department/edit/${id}`,
      },
    },
    member: {
      edit: (id: string = ':id'): string => `/company/member/${id}`,
      create: '/company/member/create',
    },
    user: {
      list: '/company/user',
      edit: (id: string = ':id'): string => `/company/user/edit/${id}`,
      create: '/company/user/create',
      detail: (id: string = ':id'): string => `/company/user/detail/${id}`,
    },
    notification: {
      company: {
        list: '/company/notification',
        detail: (id: string = ':id'): string =>
          `/company/notification/detail/${id}`,
      },
      user: {
        list: '/company/notification-user',
        create: '/company/notification-user/create',
        detail: (id: string = ':id'): string =>
          `/company/notification-user/detail/${id}`,
        edit: (id: string = ':id'): string =>
          `/company/notification-user/edit/${id}`,
      },
    },
    service: {
      list: '/company/service',
      detail: (id: string = ':id'): string => `/company/service/detail/${id}`,
    },
    changeEmail: '/company/my-page/change-email',
    changeEmailSuccess: '/company/change-email',
    changePassword: '/company/my-page/change-password',
    target: {
      list: '/company/target',
      create: '/company/target/create',
      edit: (id: string = ':id'): string => `/company/target/edit/${id}`,
    },
    questionnaire: {
      list: '/company/questionnaire/list',
      create: '/company/questionnaire/create',
      edit: (id: string = ':id'): string => `/company/questionnaire/edit/${id}`,
      detail: (id: string = ':id'): string => `/company/questionnaire/${id}`,
    },
    healthCheck: {
      list: '/company/health-check/list',
      listUserAnswer: (questionnaireId: string = ':questionnaireId'): string =>
        `/company/health-check/list-users-answer/${questionnaireId}`,
      userAnswerDetail: (
        id: string = ':questionnaireId',
        userId: string = ':userId',
      ): string =>
        `/company/health-check/${id}/answer-detail/answer-detail/${userId}`,
      create: '/company/health-check/create',
      edit: (id: string = ':id'): string => `/company/health-check/edit/${id}`,
    },
  },
  event: {
    company: {
      list: '/company/event',
      detail: (id: string = ':id'): string => `/company/event/detail/${id}`,
      create: '/company/event/create',
      edit: (id: string = ':id'): string => `/company/event/edit/${id}`,
      user: (id: string = ':id'): string => `/company/event/user/${id}`,
    },
    system: {
      list: '/system/event',
      detail: (id: string = ':id'): string => `/system/event/detail/${id}`,
      create: '/system/event/create',
      edit: (id: string = ':id'): string => `/system/event/edit/${id}`,
      user: (id: string = ':id'): string => `/system/event/user/${id}`,
    },
  },
  metType: {
    system: {
      list: '/system/metType',
      create: '/system/metType/create',
      detail: (id: string = ':id'): string => `/system/metType/detail/${id}`,
      edit: (id: string = ':id'): string => `/system/metType/edit/${id}`,
    },
    company: {
      list: '/company/metType',
    },
  },
  home: '/',
  forgotMail: '/forgot-mail',
  setPassword: '/set-password',
  download: '/download-app',
  notFound: '/not-found',
  privacyPolicy: '/privacy-policy',
  terms: '/terms',
  forgotPasswordStep1: '/:role/forgot-password-step1',
  forgotPasswordStep2: '/:role/forgot-password-step2',
  forgotPasswordStep3: '/:role/forgot-password-step3',
  forgotPasswordStep4: '/:role/forgot-password-step4',
};

export default PATH;
