import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setPasswordService } from '~/services/resetPasswordService';
import { thunkAction } from '~/utils/utils';

const initialState = {
  error: {},
  loading: false,
};

export const setPassword = createAsyncThunk(
  'auth/setPassword',
  thunkAction(async (payload: { password: string; token: string }) => {
    const result = await setPasswordService(payload.password, payload.token);
    return result?.data?.setUserPassword;
  }),
);

const SetPasswordSlice = createSlice({
  name: 'auth/setPassword',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(setPassword.fulfilled, (state) => {
      return {
        ...state,
        error: {},
        loading: false,
      };
    });
    builder.addCase(setPassword.rejected, (state, action) => {
      return {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      };
    });
    builder.addCase(setPassword.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export default SetPasswordSlice.reducer;
