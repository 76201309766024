import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  verifyChangeEmailService,
  verifyTokenService,
} from '~/services/verifyTokenService';
import { thunkAction } from '~/utils/utils';

const initialState = {
  error: {},
  loading: false,
};

export const verifyToken = createAsyncThunk(
  'auth/verifyToken',
  thunkAction((payload: { token: string }) => {
    return verifyTokenService(payload.token);
  }),
);

export const verifyChangeEmail = createAsyncThunk(
  'auth/verifyChangeEmail',
  thunkAction((token: string) => {
    return verifyChangeEmailService(token);
  }),
);

const VerifyTokenSlice = createSlice({
  name: 'auth/verifyToken',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(verifyToken.fulfilled, (state) => {
      return {
        ...state,
        error: {},
        loading: false,
      };
    });
    builder.addCase(verifyChangeEmail.fulfilled, (state) => {
      return {
        ...state,
        error: {},
        loading: false,
      };
    });
    builder.addMatcher(
      isAnyOf(verifyToken.pending, verifyChangeEmail.pending),
      (state) => ({
        ...state,
        loading: true,
      }),
    );
    builder.addMatcher(
      isAnyOf(verifyToken.rejected, verifyChangeEmail.rejected),
      (state, action) => ({
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      }),
    );
  },
});

export default VerifyTokenSlice.reducer;
