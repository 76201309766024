import { apolloClient } from '~/apollo';
import { DEFAULT_PER_PAGE } from '~/constants/common';
import {
  CREATE_NOTIFICATION,
  DELETE_NOTIFICATION,
  UPDATE_NOTIFICATION,
} from '~/graphql/mutations';
import { DETAIL_NOTIFICATION, LIST_NOTIFICATION } from '~/graphql/queries';
import {
  ListNotificationResponse,
  NotificationDetailResponse,
  NotificationInput,
  NotificationTarget,
} from '~/models/common';

export const listNotificationService = async ({
  target,
  pagination,
  where,
}: {
  target: NotificationTarget;
  pagination?: { page?: number; perPage?: number };
  where?: any;
}): Promise<ListNotificationResponse> => {
  const res = await apolloClient.query({
    query: LIST_NOTIFICATION,
    variables: {
      target,
      pagination: {
        page: pagination?.page || 1,
        perPage: pagination?.perPage || DEFAULT_PER_PAGE,
      },
      where,
    },
  });
  return res.data.listNotification;
};

export const createNotificationService = async (payload: {
  target: NotificationTarget;
  data: NotificationInput;
}): Promise<Boolean> => {
  const res = await apolloClient.mutate({
    mutation: CREATE_NOTIFICATION,
    variables: payload,
  });

  return res.data.createNotification;
};

export const fetchDetailNotificationService = async (
  notificationId: string,
): Promise<NotificationDetailResponse> => {
  const res = await apolloClient.query({
    query: DETAIL_NOTIFICATION,
    variables: {
      notificationId,
    },
  });
  return res.data.detailNotification;
};

export const deleteNotificationService = async (
  ids: string[],
): Promise<any> => {
  const res = await apolloClient.mutate({
    mutation: DELETE_NOTIFICATION,
    variables: {
      ids,
    },
  });

  return res.data.deleteNotification;
};

export const updateNotificationService = async ({
  id,
  data,
}: {
  id: string;
  data: NotificationInput;
}): Promise<any> => {
  const res = await apolloClient.mutate({
    mutation: UPDATE_NOTIFICATION,
    variables: {
      id,
      data,
    },
  });

  return res.data.updateNotification;
};
