import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import moment from 'moment';
import { ServiceType } from '~/constants/common';
import {
  ChunkUnitOfTime,
  GraphExerciseManagementResponse,
  GraphSleepResponse,
} from '~/models/common';
import { getGraph } from '~/services/graphService';
import { thunkAction } from '~/utils/utils';

const initialState = {
  graph: {} as Partial<GraphExerciseManagementResponse & GraphSleepResponse>,
  error: {},
  loading: false,
};

export const ConvertMode: {
  [key in ChunkUnitOfTime]: moment.unitOfTime.Base;
} = {
  hours: 'days',
  days: 'days',
  weeks: 'days',
  months: 'months',
};

export const chunkDateTime = (
  fromDate: Date,
  toDate: Date,
  mode: ChunkUnitOfTime,
): { from: Date; to: Date }[] => {
  const results: { from: Date; to: Date }[] = [];
  let start = moment(fromDate).startOf(ConvertMode[mode]);
  const end = moment(toDate).endOf(ConvertMode[mode]);

  while (start.isBefore(end)) {
    const from = moment(start);
    const to = moment(start);
    if (mode === ChunkUnitOfTime.Weeks) {
      to.add(6, ConvertMode[mode]).endOf(ConvertMode[mode]);
    } else {
      to.endOf(mode);
    }

    results.push({
      from: from.toDate(),
      to: (end.isBefore(to) ? end : to).toDate(),
    });
    start = start.add(1, mode);
  }

  return results;
};

export const fetchGraph = createAsyncThunk(
  'graph/getGraph',
  thunkAction(
    async (payload: {
      from: any;
      to: any;
      mode: ChunkUnitOfTime;
      userId: string;
      type: ServiceType;
    }) => {
      const res = await getGraph(
        payload.from,
        payload.to,
        payload.mode,
        payload.userId,
        payload.type,
      );
      switch (payload.type) {
        case ServiceType.ExerciseManagement:
          return res?.data?.graphExerciseManagement;
        case ServiceType.AgeGlycation:
          return res?.data?.graphAgeGlycation;
        case ServiceType.BloodGlucose:
          return res?.data?.graphBloodGlucose;
        case ServiceType.BloodPressure:
          return {
            data: res?.data?.graphBloodPressure?.data,
            value: {
              systolic: res?.data?.graphBloodPressure?.systolic,
              diastolic: res?.data?.graphBloodPressure?.diastolic,
            },
          };
        case ServiceType.CapillaryType:
          return res?.data?.graphCapillaryType;
        case ServiceType.GripStrength:
          return res?.data?.graphGripStrength;
        case ServiceType.PulseWave:
          return res?.data?.graphPulseWave;
        case ServiceType.HeartRate:
          return res?.data?.graphHeartRate;
        case ServiceType.Step:
          return res?.data?.graphStep;
        case ServiceType.Exercise:
          return res?.data?.graphExercise;
        case ServiceType.Calorie:
          return res?.data?.graphCalorie;
        case ServiceType.Stand:
          return res?.data?.graphStand;
        case ServiceType.Fatigue:
          return res?.data?.graphFatigue;
        case ServiceType.Sleep:
          return res?.data?.graphSleep;
        default:
          return res?.data?.graphUserServicesRecord;
      }
    },
  ),
);

const GraphSlice = createSlice({
  name: 'graph',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchGraph.fulfilled, (state, action) => {
      return {
        ...state,
        graph: action.payload,
        error: {},
        loading: false,
      };
    });
    builder.addMatcher(isAnyOf(fetchGraph.pending), (state) => ({
      ...state,
      graph: {},
      loading: true,
    }));
    builder.addMatcher(isAnyOf(fetchGraph.rejected), (state, action) => ({
      ...state,
      error: {
        message: action.payload,
      },
      loading: false,
    }));
  },
});

export default GraphSlice.reducer;
