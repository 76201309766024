import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
  Observable,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { LOCAL_STORAGE, MESSAGE, NOTIFY_TYPE } from '~/constants/common';
import i18n from '~/i18n';
import { refreshTokenService } from '~/services/refreshTokenService';
import { getLocalStorage, notify } from '~/utils/utils';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_BASE_URL,
});

const authHeader = (): Object => {
  const { token } = getLocalStorage(LOCAL_STORAGE.TOKEN, true) || {};

  if (token) {
    return {
      authorization: token,
      timezone: new Date().getTimezoneOffset() / -60,
    };
  }

  return {
    'x-api-key': process.env.REACT_APP_X_API_KEY,
  };
};

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      ...authHeader(),
      ...(process.env.REACT_APP_PASS_CAPTCHA === 'true' && { 'is-mobile': 1 }),
    },
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }): any => {
    if (graphQLErrors && graphQLErrors[0]?.message) {
      if (graphQLErrors[0]?.message === MESSAGE.EXPIRED_TOKEN) {
        const { token, ...refreshToken } =
          getLocalStorage(LOCAL_STORAGE.TOKEN, true) || {};
        localStorage.setItem(LOCAL_STORAGE.TOKEN, JSON.stringify(refreshToken));

        const observable = new Observable((observer) => {
          (async (): Promise<any> => {
            try {
              const { token: accessToken } = await refreshTokenService();

              if (!accessToken) {
                throw new Error('Unauthorized');
              }

              localStorage.setItem(
                LOCAL_STORAGE.TOKEN,
                JSON.stringify({ token: accessToken, ...refreshToken }),
              );

              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              };

              forward(operation).subscribe(subscriber);
            } catch (err) {
              observer.error(err);
              localStorage.removeItem(LOCAL_STORAGE.TOKEN);
              localStorage.removeItem(LOCAL_STORAGE.USER_INFO);
            }
          })();
        });

        return observable;
      }

      if (
        graphQLErrors[0]?.message === MESSAGE.UNAUTHORIZED ||
        graphQLErrors[0]?.message.includes('Not Authorized')
      ) {
        localStorage.removeItem(LOCAL_STORAGE.TOKEN);
        localStorage.removeItem(LOCAL_STORAGE.USER_INFO);
        window.location.reload();
      }
    }

    if (networkError) notify(NOTIFY_TYPE.ERROR, i18n.t('common.error')!);
  },
);

export const apolloClient = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'none',
    },
  },
});
