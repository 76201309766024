import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  MutationCreateCompanyDepartmentArgs,
  PaginationPageInfo,
  UpdateCompanyDepartmentInput,
  CompanyDepartmentResponse,
  CompanyResponse,
  PaginationInput,
  UpdateCompanyInput,
  UserUpdateProfileInput,
  ServiceTargetWhereInput,
  ServiceTarget,
  ServiceTargetInput,
  ServiceTargetOrderByInput,
  ServiceTargetCursorInput,
  CompanyWhereInput,
  CompanyOrderByInput,
  CompanyDepartmentOrderByInput,
} from '~/models/common';
import { ICompany } from '~/models/company';
import {
  changeEmail,
  changePassword,
  createCompany,
  createCompanyDepartment,
  createServiceTarget,
  deleteCompany,
  deleteCompanyDepartment,
  deleteServiceTarget,
  detailServiceTarget,
  filterListServiceTarget,
  getCompany,
  getCompanyDepartment,
  listCompanyDepartment,
  listCompanyService,
  updateCompany,
  updateCompanyDepartment,
  updateProfile,
  updateServiceTarget,
} from '~/services/companyService';
import { thunkAction } from '~/utils/utils';

const initialState = {
  listCompany: {
    data: [],
    pagination: {},
  } as any,
  listCompanyDepartment: {
    data: [] as CompanyDepartmentResponse[],
    pagination: {} as PaginationPageInfo,
  },
  listServiceTarget: {
    data: [] as ServiceTarget[],
    pagination: {} as PaginationPageInfo,
  },
  detailCompanyDepartment: {},
  detailCompany: {} as CompanyResponse,
  detailServiceTarget: {} as ServiceTarget,
  error: {},
  loading: false,
};

export const postCreateCompany = createAsyncThunk(
  'company/create',
  thunkAction((payload: ICompany) => {
    return createCompany(payload);
  }),
);

export const fetchListCompany = createAsyncThunk(
  'company/list',
  thunkAction(
    async (payload: {
      pagination: PaginationInput;
      where?: CompanyWhereInput;
      orderBy?: CompanyOrderByInput;
    }) => {
      const res = await listCompanyService(payload);
      return res;
    },
  ),
);

export const fetchDetailCompany = createAsyncThunk(
  'company/detail',
  thunkAction(async (payload: string) => {
    const res = await getCompany(payload);
    return res?.data?.detailCompany;
  }),
);

export const postUpdateCompany = createAsyncThunk(
  'company/update',
  thunkAction((payload: UpdateCompanyInput) => {
    return updateCompany(payload);
  }),
);

export const postDeleteCompany = createAsyncThunk(
  'company/delete',
  thunkAction((id: string) => {
    return deleteCompany(id);
  }),
);

export const fetchListCompanyDepartment = createAsyncThunk(
  'company/department/list',
  thunkAction(
    async (payload: {
      companyId: string;
      pagination: PaginationInput;
      orderBy: CompanyDepartmentOrderByInput;
    }) => {
      const res = await listCompanyDepartment(payload);
      return res?.data?.listCompanyDepartment;
    },
  ),
);

export const postCreateCompanyDepartment = createAsyncThunk(
  'company/department/create',
  thunkAction((payload: MutationCreateCompanyDepartmentArgs) => {
    return createCompanyDepartment(payload);
  }),
);

export const postUpdateCompanyDepartment = createAsyncThunk(
  'company/department/update',
  thunkAction((payload: UpdateCompanyDepartmentInput) => {
    return updateCompanyDepartment(payload);
  }),
);

export const postDeleteCompanyDepartment = createAsyncThunk(
  'company/department/delete',
  thunkAction((id: string) => {
    return deleteCompanyDepartment(id);
  }),
);

export const fetchDetailCompanyDepartment = createAsyncThunk(
  'company/department/detail',
  thunkAction(async (id: string) => {
    const res = await getCompanyDepartment(id);
    return res?.data?.detailDepartment;
  }),
);

export const postUpdateProfile = createAsyncThunk(
  'company/updateProfile',
  thunkAction((payload: UserUpdateProfileInput) => {
    return updateProfile(payload);
  }),
);

export const postChangeEmail = createAsyncThunk(
  'company/changeEmail',
  thunkAction((payload: { newEmail: string; password: string }) => {
    return changeEmail({
      newEmail: payload.newEmail,
      password: payload.password,
    });
  }),
);

export const postChangePassword = createAsyncThunk(
  'company/changeEmail',
  thunkAction(
    (payload: {
      oldPassword: string;
      newPassword: string;
      confirmPassword: string;
    }) => {
      return changePassword({
        ...payload,
      });
    },
  ),
);

export const fetchListServiceTarget = createAsyncThunk(
  'company/serviceTarget/list',
  thunkAction(
    async (payload: {
      where?: ServiceTargetWhereInput;
      pagination?: PaginationInput;
      orderBy?: ServiceTargetOrderByInput;
      cursor?: ServiceTargetCursorInput;
    }) => {
      const res = await filterListServiceTarget(payload);
      return res?.data?.listServiceTarget;
    },
  ),
);

export const postDeleteServiceTarget = createAsyncThunk(
  'company/serviceTarget/delete',
  thunkAction((id: string) => {
    return deleteServiceTarget(id);
  }),
);

export const postCreateServiceTarget = createAsyncThunk(
  'company/serviceTarget/create',
  thunkAction((payload: ServiceTargetInput) => {
    return createServiceTarget(payload);
  }),
);

export const fetchDetailServiceTarget = createAsyncThunk(
  'company/serviceTarget/detail',
  thunkAction(async (id: string) => {
    const res = await detailServiceTarget(id);
    return res?.data?.detailServiceTarget;
  }),
);

export const postUpdateServiceTarget = createAsyncThunk(
  'company/serviceTarget/update',
  thunkAction((payload: ServiceTargetInput) => {
    return updateServiceTarget(payload);
  }),
);

const CompanySlice = createSlice({
  name: 'createCompany',
  initialState,
  reducers: {
    setStatus(state, action) {
      return {
        ...state,
        status: action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchDetailServiceTarget.fulfilled, (state, action) => {
      return {
        ...state,
        detailServiceTarget: action.payload,
        error: {},
        loading: false,
      };
    });
    builder.addCase(fetchListServiceTarget.fulfilled, (state, action) => {
      return {
        ...state,
        listServiceTarget: action.payload,
        error: {},
        loading: false,
      };
    });
    builder.addCase(fetchListCompany.fulfilled, (state, action) => {
      return {
        ...state,
        listCompany: {
          data: [...action.payload.data],
          pagination: { ...action.payload.pagination },
        },
        error: {},
        loading: false,
      };
    });
    builder.addCase(fetchDetailCompany.fulfilled, (state, action) => {
      return {
        ...state,
        detailCompany: { ...action.payload },
        error: {},
        loading: false,
      };
    });
    builder.addCase(fetchListCompanyDepartment.fulfilled, (state, action) => {
      return {
        ...state,
        listCompanyDepartment: {
          data: [...action.payload.data],
          pagination: { ...action.payload.pagination },
        },
        error: {},
        loading: false,
      };
    });

    builder.addCase(fetchDetailCompanyDepartment.fulfilled, (state, action) => {
      return {
        ...state,
        detailCompanyDepartment: { ...action.payload },
        error: {},
        loading: false,
      };
    });
    builder.addCase(postUpdateProfile.fulfilled, (state) => ({
      ...state,
      loading: false,
    }));
    builder.addCase(postChangeEmail.fulfilled, (state) => ({
      ...state,
      loading: false,
    }));

    builder.addMatcher(
      isAnyOf(
        postCreateCompanyDepartment.fulfilled,
        postUpdateCompanyDepartment.fulfilled,
        postDeleteCompanyDepartment.fulfilled,
        postCreateCompany.fulfilled,
        postUpdateCompany.fulfilled,
        postDeleteServiceTarget.fulfilled,
        postCreateServiceTarget.fulfilled,
        postUpdateServiceTarget.fulfilled,
      ),
      (state) => ({
        ...state,
        loading: false,
      }),
    );

    builder.addMatcher(
      isAnyOf(
        postCreateCompany.pending,
        fetchListCompany.pending,
        fetchDetailCompany.pending,
        postUpdateCompany.pending,
        fetchListCompanyDepartment.pending,
        postCreateCompanyDepartment.pending,
        postUpdateCompanyDepartment.pending,
        postDeleteCompanyDepartment.pending,
        fetchDetailCompanyDepartment.pending,
        postUpdateProfile.pending,
        postChangeEmail.pending,
        fetchListServiceTarget.pending,
        postDeleteServiceTarget.pending,
        postCreateServiceTarget.pending,
        fetchDetailServiceTarget.pending,
        postUpdateServiceTarget.pending,
      ),
      (state) => ({
        ...state,
        loading: true,
      }),
    );
    builder.addMatcher(
      isAnyOf(
        postCreateCompany.rejected,
        fetchListCompany.rejected,
        fetchDetailCompany.rejected,
        postUpdateCompany.rejected,
        fetchListCompanyDepartment.rejected,
        postCreateCompanyDepartment.rejected,
        postUpdateCompanyDepartment.rejected,
        postDeleteCompanyDepartment.rejected,
        fetchDetailCompanyDepartment.rejected,
        postUpdateProfile.rejected,
        postChangeEmail.rejected,
        fetchListServiceTarget.rejected,
        postDeleteServiceTarget.rejected,
        postCreateServiceTarget.rejected,
        fetchDetailServiceTarget.rejected,
        postUpdateServiceTarget.rejected,
      ),
      (state, action) => ({
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      }),
    );
  },
});

export const { setStatus } = CompanySlice.actions;
export default CompanySlice.reducer;
