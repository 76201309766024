import { apolloClient } from '~/apollo';
import { DEFAULT_PER_PAGE } from '~/constants/common';
import { CREATE_EVENT, DELETE_EVENT, UPDATE_EVENT } from '~/graphql/mutations';
import {
  DETAIL_EVENT,
  LIST_EVENT,
  LIST_USER_JOINED_EVENT,
} from '~/graphql/queries';
import {
  DetailEventResponse,
  ListEventsResponse,
  MutationCreateEventArgs,
  MutationDeleteEventArgs,
  MutationUpdateEventArgs,
  QueryDetailEventArgs,
  QueryListEventsArgs,
  QueryListUserJoinedEventArgs,
  UsersJoinedEventResponse,
} from '~/models/common';

export const listEventService = async ({
  pagination,
  where,
  orderBy,
}: QueryListEventsArgs): Promise<ListEventsResponse> => {
  const res = await apolloClient.query({
    query: LIST_EVENT,
    variables: {
      pagination: {
        page: pagination?.page || 1,
        perPage: DEFAULT_PER_PAGE,
      },
      where,
      orderBy,
    },
  });
  return res.data.listEvents;
};

export const getEventService = async ({
  eventId,
}: QueryDetailEventArgs): Promise<DetailEventResponse> => {
  const res = await apolloClient.query({
    query: DETAIL_EVENT,
    variables: {
      eventId,
    },
  });
  return res.data.detailEvent;
};

export const createEvent = async (
  payload: MutationCreateEventArgs,
): Promise<any> => {
  return apolloClient.mutate({
    mutation: CREATE_EVENT,
    variables: {
      ...payload,
    },
  });
};

export const updateEvent = async (
  payload: MutationUpdateEventArgs,
): Promise<any> => {
  return apolloClient.mutate({
    mutation: UPDATE_EVENT,
    variables: {
      ...payload,
    },
  });
};

export const deleteEvent = async (
  payload: MutationDeleteEventArgs,
): Promise<any> => {
  return apolloClient.mutate({
    mutation: DELETE_EVENT,
    variables: {
      ...payload,
    },
  });
};

export const listUserJoinedEventService = async ({
  pagination,
  where,
  orderBy,
  eventId,
}: QueryListUserJoinedEventArgs): Promise<UsersJoinedEventResponse> => {
  const res = await apolloClient.query({
    query: LIST_USER_JOINED_EVENT,
    variables: {
      pagination: {
        page: pagination?.page || 1,
        perPage: DEFAULT_PER_PAGE,
      },
      where,
      orderBy,
      eventId,
    },
  });
  return res.data.listUserJoinedEvent;
};
