/* eslint-disable complexity */
import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { Modal, notification } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import {
  ChartMode,
  DAY_STEP,
  DAYS_OF_MOTNH,
  DAYS_OF_WEEK,
  FORMAT_DATE,
  HALF_YEAR_STEP,
  MONTH_STEP,
  MOTNHS_OF_HALF_YEAR,
  NOTIFY_TYPE,
  ROUTE,
  USER_ROLE,
} from '~/constants/common';
import i18n from '~/i18n';
import MENU from '~/layouts/Sider/Menu';
import { AppDispatch, RootState } from '~/store/store';

declare type ThunkConfig = BaseThunkAPI<RootState, AppDispatch>;
declare type ErrorInfoItem = {
  field?: string;
  message?: string;
};
declare type ErrorInfo = {
  code: number;
  message: string;
  errors: ErrorInfoItem[];
};

export const findActiveMenu = (location: any): string[] => {
  const openSubMenuKey: string[] = [];
  const pathName = location.pathname.replace(/\/(detail|edit)(.+)$/, '');

  for (let i = 0; i < MENU.length; i += 1) {
    const menuItem = MENU[i];

    if (menuItem.children && menuItem.children.length) {
      const currentRoute = menuItem.children.find(
        (item) => item.path === pathName,
      );

      if (currentRoute) {
        openSubMenuKey.push(menuItem.title, currentRoute.title);
      }
    }
  }

  return openSubMenuKey;
};

export const notify = (
  type: string,
  message: string = '',
  description: string = '',
): void => {
  notification[type]({
    message,
    description,
  });
};

export const findRoute = ({ userRoles, location }): string => {
  if (userRoles) {
    const admin = [USER_ROLE.SYSTEM_ADMIN];
    const company = [USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER];
    if (admin.find((role) => userRoles.includes(role))) {
      return ROUTE.SYSTEM;
    }
    if (company.find((role) => userRoles.includes(role))) {
      return ROUTE.COMPANY;
    }
    return '';
  }

  if (location) {
    return location.pathname.includes(ROUTE.SYSTEM)
      ? ROUTE.SYSTEM
      : ROUTE.COMPANY;
  }

  return '';
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const thunkAction = <T = undefined>(
  callback: (payload: T, config: ThunkConfig) => Promise<any>,
) => {
  return async (payload: T, config: any) => {
    try {
      return await callback(payload, config);
    } catch (error: any) {
      if (
        error?.graphQLErrors &&
        error?.graphQLErrors[0] &&
        error?.graphQLErrors[0]
      ) {
        return config.rejectWithValue(error?.graphQLErrors[0]);
      }

      throw new Error(error?.message);
    }
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */

export const handleError = (
  result: any,
  defaultMessage: string,
  customError?: (errorInfo: ErrorInfo) => void,
): void => {
  const { errorInfo } = result?.payload || {};

  if (errorInfo) {
    if (customError) {
      return customError(errorInfo);
    }

    return notify(NOTIFY_TYPE.ERROR, errorInfo.message);
  }

  if (result?.payload?.message) {
    return notify(NOTIFY_TYPE.ERROR, result?.payload?.message);
  }

  if (result?.error?.message) {
    return notify(NOTIFY_TYPE.ERROR, result?.error?.message);
  }

  return notify(NOTIFY_TYPE.ERROR, defaultMessage);
};

export const getLocalStorage = (key: string, parse: boolean = false): any => {
  const value = localStorage.getItem(key);

  if (value && parse) {
    try {
      return JSON.parse(value);
    } catch (error) {
      return null;
    }
  }

  return value;
};

export const formatDate = (date: Date, formatType = FORMAT_DATE): string => {
  if (!date) return '';
  return moment(date).format(formatType);
};

export const calculateAge = (birthday: Date): number | null => {
  return birthday ? moment().diff(moment(new Date(birthday)), 'years') : null;
};
export const confirmDelete = (
  onOk?: () => void,
  onCancel?: () => void,
): void => {
  Modal.confirm({
    title: i18n.t('common.delete-confirm'),
    okText: i18n.t('common.ok'),
    cancelText: i18n.t('common.cancel'),
    onOk,
    onCancel,
  });
};
export const objectToSearchString = (obj: Record<string, any>): string => {
  const query = { ...obj };
  Object.keys(query).forEach((param) => {
    if (
      query[param] === undefined ||
      query[param] === null ||
      query[param] === ''
    ) {
      delete query[param];
    } else {
      let q = query[param];
      if (Array.isArray(q)) {
        q = q.filter((d) => d !== undefined && d !== null && d !== '');
      }
      if (q.length === 0) {
        delete query[param];
      } else {
        query[param] = q;
      }
    }
  });
  return `?${new URLSearchParams(query).toString()}`;
};

export const calculateDateFromTo = (originDate, mode): any[] => {
  const date = originDate.clone();

  switch (mode) {
    case ChartMode.WEEK: {
      if (date.diff(dayjs(), 'day') === 0)
        return [date.subtract(DAYS_OF_WEEK, 'day'), date];
      const dateTo = date.add(DAY_STEP, 'day').isBefore(dayjs())
        ? date.add(DAY_STEP, 'day')
        : dayjs();
      const subDay = DAYS_OF_WEEK - dayjs().diff(date, 'day');
      const dateFrom = date.subtract(
        subDay <= DAY_STEP ? DAY_STEP : subDay,
        'day',
      );
      return [dateFrom, dateTo];
    }

    case ChartMode.MONTH: {
      if (date.diff(dayjs(), 'day') === 0)
        return [date.subtract(DAYS_OF_MOTNH, 'day'), date];

      const subDay = DAYS_OF_MOTNH - dayjs().diff(date, 'day');
      const dateFrom = date.subtract(
        subDay <= MONTH_STEP ? MONTH_STEP : subDay,
        'day',
      );
      const dateTo = date.add(MONTH_STEP, 'day').isBefore(dayjs())
        ? date.add(MONTH_STEP, 'day')
        : dayjs();
      return [dateFrom, dateTo];
    }

    case ChartMode.HALF_YEAR: {
      if (date.diff(dayjs(), 'month') === 0)
        return [date.subtract(MOTNHS_OF_HALF_YEAR, 'month'), date];

      const subMonth = MOTNHS_OF_HALF_YEAR - dayjs().diff(date, 'month');
      const dateFrom = date.subtract(
        subMonth <= HALF_YEAR_STEP ? HALF_YEAR_STEP : subMonth,
        'month',
      );
      const dateTo = date.add(HALF_YEAR_STEP, 'month').isBefore(dayjs())
        ? date.add(HALF_YEAR_STEP, 'month')
        : dayjs();
      return [dateFrom, dateTo];
    }

    case ChartMode.YEAR: {
      if (date.diff(dayjs(), 'year') === 0) return [date.startOf('year'), date];

      const dateFrom = date.startOf('year');
      const dateTo = date.endOf('year');
      return [dateFrom, dateTo];
    }
    default:
      return [date.startOf('day'), date.endOf('day')];
  }
};

export const paramsToObject = (entries): any => {
  const result = {};
  // eslint-disable-next-line no-restricted-syntax, prefer-const
  for (let [key, value] of entries) {
    // eslint-disable-next-line no-continue
    if (value === 'null' || value === 'undefined') continue;
    if (value === 'true' || value === 'false') value = Boolean(value);

    result[key] = value;
  }
  return result;
};

export const downloadFile = ({
  data,
  fileName,
  fileType,
}: {
  data: string;
  fileName: string;
  fileType: string;
}): void => {
  const blob = new Blob(['\uFEFF', data], { type: fileType });

  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export const formatMoney = (amount?: number, toFixed = 0): string => {
  if (amount === undefined || amount === null) return '';
  let [integerPart, decimalPart] = String(amount).split('.');

  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (decimalPart && decimalPart.length > 2) {
    decimalPart = decimalPart.slice(0, toFixed);
  }

  const formattedAmount = decimalPart
    ? `${integerPart}.${decimalPart}`
    : integerPart;

  return formattedAmount;
};
