import { HomeDataKey, SortOrder } from '~/models/common';

export const USER_ROLE = {
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
  COMPANY: 'COMPANY',
  COMPANY_MEMBER: 'COMPANY_MEMBER',
} as const;

export enum ErrorCode {
  UNKNOWN_ERROR = 0,
  VALIDATE_ERROR = 1,
  WRONG_USERNAME_OR_PASSWORD = 2,
  INVALID_RECAPTCHA_TOKEN = 3,
  UNAUTHORIZED = 4,
  FORBIDDEN = 5,
  EMAIL_ALREADY_EXISTS = 6,
  INVALID_TOKEN = 7,
  FAILED_CERATE_COGNITO = 8,
  INCORRECT_FORMAT_PASSWORD = 9,
  USER_NOT_FOUND = 10,
  SEND_MAIL_ERROR = 11,
  CONFIRM_PASSWORD_NOT_MATCH_PASSWORD = 12,
  SAME_PASSWORD = 13,
  ERROR_CREATED_USER_SERVICE = 14,
  INVALID_MODE_CHUNK_DATE_TIME = 15,
  LIMITED_USER_SERVICE = 16,
  INVALID_RECORD_TIME = 18,
  RECORD_NOT_FOUND = 19,
  INVALID_GENDER = 20,
  INVALID_LAST_SYNCED_AT = 21,
  NOT_FOUND_QUEUE_URL = 22,
  SQS_SEND_MESSAGE_ERROR = 23,
  SYNC_DATA_ERROR = 24,
  SYNC_DATA_INVALID_INPUT = 25,
  INVALID_POSITIVE_INPUT = 26,
  INVALID_CAPILLARY_TYPE_RANGE = 27,
  SYSTOLIC_NOT_GRATER_DIASTOLIC = 28,
  NO_SUCH_KEY = 29,
  USERS_EXIST_IN_EVENT = 30,
}

export const NOTIFY_TYPE = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
};

export const LOCAL_STORAGE = {
  TOKEN: 'TOKEN',
  USER_INFO: 'USER_INFO',
} as const;

export const ROUTE = {
  SYSTEM: 'system',
  COMPANY: 'company',
};

export const MESSAGE = {
  EXPIRED_TOKEN: 'Token has expired.',
  UNAUTHORIZED: 'Unauthorized',
};

export const LOCALE = {
  JAPANESE: 'jp',
  ENGLISH: 'en',
};

export const FORMAT_DATE = 'YYYY-MM-DD';
export const FORMAT_DATE_WITH_HOUR = 'YYYY-MM-DD HH:mm';
export const FORMAT_DATE_WITH_SECOND = 'YYYY-MM-DD HH:mm:ss';
export const FORMAT_DATE_PLACEHOLDER = '0000-00-00';

export const HEIGHT_LIMIT = {
  MIN: 90,
  MAX: 270,
};

export const WEIGHT_LIMIT = {
  MIN: 25,
  MAX: 255,
};

export const BIRTHDAY_LIMIT = {
  MIN: 10,
  MAX: 120,
};

export const DEFAULT_PER_PAGE = 20;

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  INVITED = 'INVITED',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
}

export const SortType = {
  ascend: SortOrder.Asc,
  descend: SortOrder.Desc,
};

export enum ChartMode {
  DAY = '1',
  WEEK = '2',
  MONTH = '3',
  HALF_YEAR = '4',
  YEAR = '5',
}

export enum ServiceType {
  ExerciseManagement = '1',
  BloodGlucose = '2',
  PulseWave = '3',
  GripStrength = '4',
  BloodPressure = '5',
  AgeGlycation = '6',
  CapillaryType = '7',
  HeartRate = '8',
  Sleep = '9',
  Step = '10',
  Exercise = '11',
  Calorie = '12',
  Stand = '13',
  Walking = '14',
  Running = '15',
  Fatigue = '16',
}

export enum SubServiceType {
  Calorie = 'calorie',
  Time = 'time',
  Body_Weight = 'weight',
  Bulk_Modulus = 'bulkModulus',
  Close_Cuff = 'closePressure',
  Open_Cuff = 'openPressure',
  Expansion_Rate = 'expansionRate',
  Vascular_Age = 'vascularAge',
  Highest = 'systolic',
  Lowest = 'diastolic',
}

export const optionsService = [
  {
    value: ServiceType.ExerciseManagement,
    label: '運動管理',
    children: [
      {
        value: SubServiceType.Calorie,
        label: 'カロリー',
        unit: 'kcal',
      },
      {
        value: SubServiceType.Time,
        label: '時間',
        unit: '分',
      },
      {
        value: SubServiceType.Body_Weight,
        label: '体重',
        unit: 'kg',
      },
    ],
  },
  { value: ServiceType.BloodGlucose, label: '自己血糖値', unit: 'mg/dl' },
  {
    value: ServiceType.PulseWave,
    label: '脈波測定',
    children: [
      {
        value: SubServiceType.Bulk_Modulus,
        label: '体積弾性率',
        unit: '%',
      },
      {
        value: SubServiceType.Close_Cuff,
        label: '閉口カフ圧',
        unit: 'mmHg',
      },
      {
        value: SubServiceType.Open_Cuff,
        label: '開口カフ圧',
        unit: 'mmHg',
      },
      {
        value: SubServiceType.Expansion_Rate,
        label: '拡張率',
        unit: '%',
      },
      {
        value: SubServiceType.Vascular_Age,
        label: '血管年齢',
        unit: '歳',
      },
    ],
  },
  { value: ServiceType.GripStrength, label: '握力測定', unit: 'kg' },
  {
    value: ServiceType.BloodPressure,
    label: '血圧測定',
    children: [
      {
        value: SubServiceType.Highest,
        label: '最高',
        unit: 'mmHg',
      },
      {
        value: SubServiceType.Lowest,
        label: '最低',
        unit: 'mmHg',
      },
    ],
  },
  { value: ServiceType.AgeGlycation, label: 'AGEs糖化測定', unit: '歳' },
  { value: ServiceType.CapillaryType, label: '毛細血管タイプ' },
  {
    isSpecial: true,
    noPagination: true,
    value: ServiceType.HeartRate,
    label: '心拍数',
  },
  {
    isSpecial: true,
    noPagination: true,
    value: ServiceType.Sleep,
    label: '睡眠',
  },
  {
    isSpecial: true,
    noPagination: true,
    value: ServiceType.Step,
    label: '歩数',
  },
  {
    isSpecial: true,
    noPagination: true,
    value: ServiceType.Exercise,
    label: 'エクササイズ',
  },
  {
    isSpecial: true,
    noPagination: true,
    value: ServiceType.Calorie,
    label: '消費カロリー',
  },
  {
    isSpecial: true,
    noPagination: true,
    value: ServiceType.Stand,
    label: 'スタンド時間',
  },
  {
    isSpecial: true,
    value: ServiceType.Walking,
    label: '歩く',
  },
  {
    isSpecial: true,
    value: ServiceType.Running,
    label: 'ランニング',
  },
  {
    isSpecial: true,
    value: ServiceType.Fatigue,
    label: '疲労指数',
  },
];

export const DAYS_OF_WEEK = 6;
export const DAYS_OF_MOTNH = 30;
export const MOTNHS_OF_HALF_YEAR = 6;

export const DAY_STEP = 3;
export const MONTH_STEP = 15;
export const HALF_YEAR_STEP = 3;

export const DEFAULT_IMAGE = {
  EVENT_MAIN_IMAGE: 'event/images/default/event_main_image.png',
  EVENT_SUB_IMAGE: 'event/images/default/event_sub_image.png',
};

export const BLOOD_PRESSURE_RANGE = {
  SYSTOLIC: {
    MIN: 60,
    MAX: 260,
  },
  DIASTOLIC: {
    MIN: 40,
    MAX: 215,
  },
};

export const EXPORT_SERVICE_MAPPING = {
  [HomeDataKey.Badminton]: {
    name: 'バドミントン',
  },
  [HomeDataKey.Basketball]: {
    name: 'バスケットボール',
  },
  [HomeDataKey.Calorie]: {
    name: '消費カロリー',
  },
  [HomeDataKey.Cycling]: {
    name: 'サイクリング',
  },
  [HomeDataKey.Dance]: {
    name: 'ダンス',
  },
  [HomeDataKey.Football]: {
    name: 'サッカー',
  },
  [HomeDataKey.Running]: {
    name: 'ランニング',
  },
  [HomeDataKey.HeartRate]: {
    name: '心拍数',
  },
  [HomeDataKey.RockClimbing]: {
    name: '山登り',
  },
  [HomeDataKey.Skate]: {
    name: 'スケート',
  },
  [HomeDataKey.Sleep]: {
    name: '睡眠',
  },
  [HomeDataKey.Stand]: {
    name: 'スタンド時間',
  },
  [HomeDataKey.Step]: {
    name: '歩数',
  },
  [HomeDataKey.Swim]: {
    name: '水泳',
  },
  [HomeDataKey.TableTennis]: {
    name: '卓球',
  },
  [HomeDataKey.Tennis]: {
    name: 'テニス',
  },
  [HomeDataKey.VolleyBall]: {
    name: 'バレーボール',
  },
  [HomeDataKey.Walking]: {
    name: '歩く',
  },
  [HomeDataKey.Yoga]: {
    name: 'ヨガ',
  },
  [HomeDataKey.Fatigue]: {
    name: '疲労度',
  },
  [HomeDataKey.GymExercise]: {
    name: 'エアロビクス',
  },
  [HomeDataKey.Exercise]: {
    name: 'エクササイズ',
  },
} as {
  [key in HomeDataKey]: {
    name: string;
  };
};

export enum SleepMode {
  IV_SA_STATE_SLEEP_NN = 0,
  IV_SA_STATE_SLEEP_IN,
  IV_SA_STATE_SLEEP_OUT,
  IV_SA_STATE_SLEEP_DEEP_SLEEP,
  IV_SA_STATE_SLEEP_SHALLOW_SLEEP,
  IV_SA_STATE_SLEEP_LAY_UP,
  IV_SA_STATE_SLEEP_WAKE_UP,
  IV_SA_STATE_SLEEP_EYE_MOVE,
}

export const DUMMY_COMPANY_ID = 'dummy-company-id';
