import { apolloClient } from '~/apollo';
import {
  FILTER_LIST_COMPANY_DEPARTMENT,
  GET_UPLOAD_URL,
  LIST_DEPARTMENT,
  LIST_PREFECTURE,
  LIST_SERVICE,
  LIST_SERVICE_ITEM,
} from '~/graphql/queries';
import {
  CompanyDepartmentWhereInput,
  ListCompanyMemberResponse,
  PaginationInput,
  ServiceItemOrderByInput,
  ServiceItemResponse,
  ServiceItemWhereInput,
  ServiceOrderByInput,
  ServiceWhereInput,
} from '~/models/common';

export const listService = async (
  companyId?: any,
  isCountUser: boolean = false,
  where?: ServiceWhereInput,
  pagination?: PaginationInput,
  orderBy?: ServiceOrderByInput,
  orderByItem?: ServiceItemOrderByInput,
): Promise<any> => {
  return apolloClient.query({
    query: LIST_SERVICE(isCountUser),
    variables: {
      where: {
        ...where,
        companyServices: companyId
          ? {
              some: {
                companyId: {
                  equals: companyId,
                },
              },
            }
          : undefined,
      },
      pagination,
      orderBy,
      orderByItem,
    },
  });
};

export const listPrefecture = async (): Promise<any> => {
  return apolloClient.query({
    query: LIST_PREFECTURE,
    variables: {},
  });
};

export const listDepartment = async (companyId: string): Promise<any> => {
  return apolloClient.query({
    query: LIST_DEPARTMENT,
    variables: {
      where: {
        companyId: {
          equals: companyId,
        },
      },
    },
  });
};

export const getUploadUrl = async (
  fileName: string,
  contentType: string,
): Promise<any> => {
  return apolloClient.query({
    query: GET_UPLOAD_URL,
    variables: {
      fileName,
      contentType,
    },
  });
};

export const uploadS3File = async (url: string, file: any): Promise<any> => {
  return fetch(url, {
    method: 'PUT',
    body: file,
  });
};

export const filterListCompanyDepartment = async ({
  where,
}: {
  where: CompanyDepartmentWhereInput;
}): Promise<ListCompanyMemberResponse> => {
  const res = await apolloClient.query({
    query: FILTER_LIST_COMPANY_DEPARTMENT,
    variables: {
      where,
    },
  });
  return res.data.listCompanyDepartment;
};

export const ListServiceItem = async ({
  where,
}: {
  where?: ServiceItemWhereInput;
}): Promise<ServiceItemResponse[]> => {
  const res = await apolloClient.query({
    query: LIST_SERVICE_ITEM,
    variables: {
      where,
    },
  });
  return res.data.listServiceItem;
};
