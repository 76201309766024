import { apolloClient } from '~/apollo';
import {
  FORGOT_PASSWORD,
  SEND_MAIL_FORGOT_PASSWORD,
} from '~/graphql/mutations';

export const sendMailForgotPassword = async (email: string): Promise<any> => {
  return apolloClient.mutate({
    mutation: SEND_MAIL_FORGOT_PASSWORD,
    variables: {
      email,
    },
  });
};

export const changePassword = async (
  newPassword: string,
  confirmPassword: string,
  token: string,
): Promise<any> => {
  return apolloClient.mutate({
    mutation: FORGOT_PASSWORD,
    variables: {
      newPassword,
      confirmPassword,
      token,
    },
  });
};
