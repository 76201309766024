import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  CreateServiceInput,
  PaginationInput,
  PaginationPageInfo,
  ServiceItemOrderByInput,
  ServiceItemResponse,
  ServiceItemWhereInput,
  ServiceOrderByInput,
  ServiceResponse,
  ServiceWhereInput,
  UpdateServiceInput,
} from '~/models/common';
import { ListServiceItem, listService } from '~/services/commonService';
import {
  createSystemService,
  detailSystemService,
  updateSystemService,
} from '~/services/systemService';
import { thunkAction } from '~/utils/utils';

const initialState = {
  listService: {
    data: [] as ServiceResponse[],
    pagination: {} as PaginationPageInfo,
  },
  detailService: null as ServiceResponse | null,
  listServiceItem: [] as ServiceItemResponse[],
  loading: false,
  error: {},
};

export const postCreateService = createAsyncThunk(
  'service/postCreateService',
  thunkAction((payload: CreateServiceInput) => {
    return createSystemService(payload);
  }),
);

export const fetchListService = createAsyncThunk(
  'service/listService',
  thunkAction(
    async (payload: {
      companyId?: string | undefined;
      isCountUser?: boolean;
      pagination?: PaginationInput;
      where?: ServiceWhereInput;
      orderBy?: ServiceOrderByInput;
      orderByItem?: ServiceItemOrderByInput;
    }) => {
      const result = await listService(
        payload.companyId,
        payload.isCountUser,
        payload.where,
        payload.pagination,
        payload.orderBy,
        payload.orderByItem,
      );

      return result?.data?.listService;
    },
  ),
);

export const fetchDetailService = createAsyncThunk(
  'service/detailService',
  thunkAction(async (payload: { id: string }) => {
    const result = await detailSystemService(payload.id);
    return result?.data?.detailService;
  }),
);

export const postUpdateService = createAsyncThunk(
  'service/updateService',
  thunkAction((payload: UpdateServiceInput) => {
    return updateSystemService(payload);
  }),
);

export const fetchListServiceItem = createAsyncThunk(
  'service/listServiceItem',
  thunkAction((payload: { where?: ServiceItemWhereInput }) => {
    return ListServiceItem(payload);
  }),
);

const ServiceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    resetListServiceItem: (state) => ({ ...state, listServiceItem: [] }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchListServiceItem.fulfilled, (state, action) => {
      return {
        ...state,
        listServiceItem: action.payload,
        loading: false,
      };
    });
    builder.addCase(fetchListService.fulfilled, (state, action) => {
      return {
        ...state,
        listService: action.payload,
        loading: false,
      };
    });
    builder.addCase(fetchDetailService.fulfilled, (state, action) => {
      return {
        ...state,
        detailService: action.payload,
        loading: false,
      };
    });
    builder.addMatcher(
      isAnyOf(
        postCreateService.pending,
        fetchListService.pending,
        fetchDetailService.pending,
        postUpdateService.pending,
        fetchListServiceItem.pending,
      ),
      (state) => {
        return {
          ...state,
          loading: true,
        };
      },
    );
    builder.addMatcher(
      isAnyOf(postCreateService.fulfilled, postUpdateService.fulfilled),
      (state) => {
        return {
          ...state,
          loading: false,
        };
      },
    );
    builder.addMatcher(
      isAnyOf(
        postCreateService.rejected,
        fetchListService.rejected,
        fetchDetailService.rejected,
        postUpdateService.rejected,
        fetchListServiceItem.rejected,
      ),
      (state, action) => ({
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      }),
    );
  },
});

export const { resetListServiceItem } = ServiceSlice.actions;
export default ServiceSlice.reducer;
