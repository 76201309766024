import { Col, Dropdown, Layout, MenuProps, Modal, Row } from 'antd';
import classNames from 'classnames/bind';
import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Permission from '~/components/Common/Permission';
import { ROUTE, USER_ROLE } from '~/constants/common';
import { setLogout } from '~/store/slices/auth/login';
import { AppDispatch, RootState } from '~/store/store';
import { findRoute } from '~/utils/utils';

import PATH from '~/constants/path';
import ProfileDetail from '~/pages/company/Profile/Detail';
import { setIsOpenProfile } from '~/store/slices/common';
import styles from './index.module.scss';

// import logoImg from '~/assets/logo.svg';
const cx = classNames.bind(styles);

interface HeaderProps {
  isAuthenticated?: boolean;
}

const Header = ({ isAuthenticated }: HeaderProps): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const userRoles = useSelector(
    (state: RootState) => state.auth?.userInfo?.userRoles,
  );
  const currentUser = useSelector((state: RootState) => state.auth?.userInfo);
  const { t } = useTranslation();
  const routePath = findRoute({ userRoles, location }) || ROUTE.COMPANY;
  const navigate = useNavigate();

  const handleLogout = (): void => {
    Modal.confirm({
      title: t('common.menu.logout'),
      content: t('common.logout-confirm'),
      okText: t('common.ok'),
      cancelText: t('common.cancel'),
      onOk: () => {
        dispatch(setLogout());
      },
      okButtonProps: {
        className: cx('logout-button'),
      },
      cancelButtonProps: {
        className: cx('logout-button'),
      },
    });
  };

  const menuItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <b style={{ display: 'block', textAlign: 'center' }}>
          {t('common.menu.my-page')!}
        </b>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '2',
      label: t('common.menu.user-info')!,
      onClick: (): void => {
        dispatch(setIsOpenProfile(true));
      },
    },
    {
      key: '3',
      label: t('common.menu.change-email')!,
      onClick: (): void => {
        navigate(PATH.company.changeEmail);
      },
    },
    {
      key: '4',
      label: t('common.menu.change-password')!,
      onClick: (): void => {
        navigate(PATH.company.changePassword);
      },
    },
    {
      key: '5',
      label: <span>{t('common.menu.logout')}</span>,
      onClick: handleLogout,
    },
  ];

  return (
    <Layout.Header
      className={cx({
        header: true,
        'system-color': routePath === ROUTE.SYSTEM,
      })}
    >
      <Row align="middle" style={{ height: '100%' }}>
        <Col span={4} className={cx('logo')}>
          {/* <img src={logoImg} /> */}
        </Col>
        {isAuthenticated && (
          <Col span={20} flex="end">
            <div className={cx('user')}>
              <Permission roles={[USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER]}>
                <div className={cx('user-avatar')}>
                  {currentUser?.avatar ? (
                    <img src={currentUser?.avatar} alt="" />
                  ) : (
                    <UserOutlined style={{ fontSize: 20 }} />
                  )}
                </div>
              </Permission>
              <div className={cx('user-info')}>
                <Permission
                  roles={[USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER]}
                >
                  <span>{currentUser?.company?.name}</span>
                  <Dropdown
                    menu={{ items: menuItems }}
                    trigger={['click']}
                    arrow
                    overlayClassName={cx('dropdown')}
                  >
                    <a href="#" className={cx('text-decoration')}>
                      {currentUser?.name}
                    </a>
                  </Dropdown>
                  <span></span>
                </Permission>
                <Permission roles={[USER_ROLE.SYSTEM_ADMIN]}>
                  <a
                    href="#"
                    onClick={handleLogout}
                    className={cx('text-decoration')}
                  >
                    {t('common.menu.logout')}
                  </a>
                </Permission>
              </div>
            </div>
            <ProfileDetail />
          </Col>
        )}
      </Row>
    </Layout.Header>
  );
};

export default Header;
