import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LOCAL_STORAGE } from '~/constants/common';
import { getUserInfoService, loginService } from '~/services/loginService';
import { getLocalStorage, thunkAction } from '~/utils/utils';

const initialState = {
  isAuthenticated: !!getLocalStorage(LOCAL_STORAGE.TOKEN),
  tokenInfo: getLocalStorage(LOCAL_STORAGE.TOKEN, true),
  userInfo: getLocalStorage(LOCAL_STORAGE.USER_INFO, true),
  error: {},
  loading: false,
  restoreToken: false,
  lastRoles: [],
};

export const login = createAsyncThunk(
  'auth/login',
  thunkAction(
    async (payload: {
      email: string;
      password: string;
      captchaToken: string;
      userGroup: string;
    }) => {
      const result = await loginService(
        payload.email,
        payload.password,
        payload.captchaToken,
        payload.userGroup,
      );

      return result?.data?.signIn;
    },
  ),
);

export const getUserInfo = createAsyncThunk(
  'auth/getUserInfo',
  thunkAction(async () => {
    const result = await getUserInfoService();

    return result?.data?.getUserInfo;
  }),
);

const LoginSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth(state, action) {
      return {
        ...state,
        auth: action.payload,
      };
    },
    setLogout(state) {
      const info = getLocalStorage(LOCAL_STORAGE.USER_INFO, true);

      localStorage.removeItem(LOCAL_STORAGE.TOKEN);
      localStorage.removeItem(LOCAL_STORAGE.USER_INFO);

      return {
        ...state,
        isAuthenticated: false,
        userInfo: null,
        tokenInfo: null,
        error: {},
        loading: false,
        restoreToken: false,
        lastRoles: info?.userRoles || [],
      };
    },
    setRestoreToken(state, action) {
      return {
        ...state,
        restoreToken: action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(login.fulfilled, (state, action) => {
      return {
        ...state,
        error: {},
        tokenInfo: {
          ...action.payload,
        },
        isAuthenticated: true,
        loading: false,
      };
    });
    builder.addCase(login.rejected, (state, action) => {
      return {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      };
    });
    builder.addCase(login.pending, (state) => {
      return {
        ...state,
        loading: true,
        userInfo: null,
        tokenInfo: null,
      };
    });
    builder.addCase(getUserInfo.rejected, (state, action) => {
      return {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
        restoreToken: false,
      };
    });
    builder.addCase(getUserInfo.pending, (state) => {
      return {
        ...state,
        restoreToken: true,
        loading: true,
      };
    });
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      localStorage.setItem(
        LOCAL_STORAGE.USER_INFO,
        JSON.stringify(action.payload),
      );
      return {
        ...state,
        userInfo: action.payload,
        restoreToken: false,
        loading: false,
      };
    });
  },
});

export const { setAuth, setLogout, setRestoreToken } = LoginSlice.actions;
export default LoginSlice.reducer;
