import { apolloClient } from '~/apollo';
import { LOCAL_STORAGE } from '~/constants/common';
import { REFRESH_TOKEN } from '~/graphql/mutations';
import { getLocalStorage } from '~/utils/utils';

export const refreshTokenService = async (): Promise<any> => {
  try {
    const { refreshToken } = getLocalStorage(LOCAL_STORAGE.TOKEN, true) || {};

    if (!refreshToken) throw new Error('Unauthorized');

    const res = await apolloClient.mutate({
      mutation: REFRESH_TOKEN,
      variables: { refreshToken },
    });

    return res.data.refreshToken;
  } catch (e: any) {
    throw new Error(e.message);
  }
};
