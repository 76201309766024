import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  CreateQuestionnaireInput,
  DeleteQuestionnaireInput,
  DetailUserAnswerQuestionnaireInput,
  PaginationInput,
  PaginationPageInfo,
  QuestionnaireOrderByInput,
  QuestionnaireResponse,
  QuestionnaireWhereInput,
  UpdateQuestionnaireInput,
  UserOrderByInput,
  UserResponse,
  UserWhereInput,
} from '~/models/common';
// eslint-disable-next-line import/no-cycle
import {
  createQuestionnaireService,
  deleteQuestionnaireService,
  fetchDetailQuestionnaireService,
  fetchDetailUserAnswerQuestionnaireService,
  listQuestionnaireService,
  updateQuestionnaireService,
} from '~/services/questionnaire';
// eslint-disable-next-line import/no-cycle
import { listUser } from '~/services/userService';
// eslint-disable-next-line import/no-cycle
import { thunkAction } from '~/utils/utils';

interface IQuestionnaireState {
  listHealthCheck: {
    data: QuestionnaireResponse[];
    pagination: PaginationPageInfo;
  };
  listUser: {
    data: UserResponse[];
    pagination: PaginationPageInfo;
  };
  userAnswerDetail?: QuestionnaireResponse;
  error: any;
  loading: boolean;
  created: Boolean;
  updated: Boolean;
  deleted: Boolean;
  detailHealthCheck?: QuestionnaireResponse;
}

const initialState: IQuestionnaireState = {
  listHealthCheck: {
    data: [],
    pagination: {} as PaginationPageInfo,
  },
  listUser: {
    data: [],
    pagination: {} as PaginationPageInfo,
  },
  error: {},
  loading: false,
  updated: false,
  created: false,
  deleted: false,
};

export const fetchListHealthCheck = createAsyncThunk(
  'healthCheck/listHealthCheck',
  thunkAction(
    async (payload: {
      pagination: PaginationInput;
      where?: QuestionnaireWhereInput;
      orderBy?: QuestionnaireOrderByInput;
    }) => {
      return listQuestionnaireService(
        payload.where,
        payload.pagination,
        payload.orderBy,
      );
    },
  ),
);
export const postCreateHealthCheckForm = createAsyncThunk(
  'healthCheck/createHealthCheck',
  thunkAction(async (data: CreateQuestionnaireInput) => {
    return createQuestionnaireService(data);
  }),
);
export const fetchListUsersAnswerHealthCheckForm = createAsyncThunk(
  'healthCheck/listUsersAnswerHealthCheck',
  thunkAction(
    async (payload: {
      pagination: PaginationInput;
      where: UserWhereInput;
      orderBy: UserOrderByInput;
    }) => {
      return listUser(payload);
    },
  ),
);
export const fetchUserAnswerHealthCheckForm = createAsyncThunk(
  'healthCheck/userAnswerHealthCheck',
  thunkAction(async (payload: DetailUserAnswerQuestionnaireInput) => {
    return fetchDetailUserAnswerQuestionnaireService(payload);
  }),
);
export const postDeleteHealthCheckForm = createAsyncThunk(
  'healthCheck/deleteHealthCheck',
  thunkAction(async (payload: DeleteQuestionnaireInput) => {
    return deleteQuestionnaireService(payload);
  }),
);
export const fetchDetailHealthCheck = createAsyncThunk(
  'healthCheck/detailHealthCheck',
  thunkAction(async (id: string) => {
    return fetchDetailQuestionnaireService(id);
  }),
);
export const postUpdateHealthCheck = createAsyncThunk(
  'healthCheck/update',
  thunkAction(async (data: UpdateQuestionnaireInput) => {
    return updateQuestionnaireService(data);
  }),
);

const HealthCheckSlice = createSlice({
  initialState,
  name: 'healthCheck',
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postCreateHealthCheckForm.fulfilled, (state, action) => ({
      ...state,
      error: {},
      loading: false,
      created: action.payload.data,
    }));
    builder.addCase(fetchListHealthCheck.fulfilled, (state, action) => ({
      ...state,
      listHealthCheck: {
        data: [...action.payload.data],
        pagination: action.payload.pagination,
      },
      error: {},
      loading: false,
    }));
    builder.addCase(
      fetchUserAnswerHealthCheckForm.fulfilled,
      (state, action) => ({
        ...state,
        userAnswerDetail: action.payload,
        error: {},
        loading: false,
      }),
    );
    builder.addCase(
      fetchListUsersAnswerHealthCheckForm.fulfilled,
      (state, action) => ({
        ...state,
        listUser: {
          data: [...action.payload.data],
          pagination: action.payload.pagination,
        },
        error: {},
        loading: false,
      }),
    );
    builder.addCase(postDeleteHealthCheckForm.fulfilled, (state, action) => ({
      ...state,
      error: {},
      loading: false,
      deleted: action.payload.data,
    }));
    builder.addCase(fetchDetailHealthCheck.fulfilled, (state, action) => ({
      ...state,
      error: {},
      detailHealthCheck: action.payload,
      loading: false,
    }));
    builder.addCase(postUpdateHealthCheck.fulfilled, (state, action) => ({
      ...state,
      error: {},
      loading: false,
      updated: action.payload.data,
    }));
    builder.addMatcher(
      isAnyOf(
        fetchListHealthCheck.pending,
        postCreateHealthCheckForm.pending,
        fetchListUsersAnswerHealthCheckForm.pending,
        fetchUserAnswerHealthCheckForm.pending,
        postDeleteHealthCheckForm.pending,
        fetchDetailHealthCheck.pending,
        postUpdateHealthCheck.pending,
      ),
      (state) => ({
        ...state,
        loading: true,
      }),
    );
    builder.addMatcher(
      isAnyOf(
        fetchListHealthCheck.rejected,
        postCreateHealthCheckForm.rejected,
        fetchListUsersAnswerHealthCheckForm.rejected,
        fetchUserAnswerHealthCheckForm.rejected,
        postDeleteHealthCheckForm.rejected,
        fetchDetailHealthCheck.rejected,
        postUpdateHealthCheck.rejected,
      ),
      (state, action) => ({
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      }),
    );
  },
});

export default HealthCheckSlice.reducer;
