import { OverrideToken } from 'antd/es/theme/interface';

const components: OverrideToken = {
  Menu: {
    colorItemBgSelected: 'rgba(126, 109, 214, 0.2)',
    colorItemTextSelected: '#7E6DD6',
  },
  Dropdown: {
    borderRadius: 4,
  },
};

export default components;
