/* eslint-disable consistent-return */
/* eslint-disable no-console */
import { apolloClient } from '~/apollo';
import { SET_PASSWORD } from '~/graphql/mutations';

export const setPasswordService = async (
  password: string,
  token: string,
): Promise<any> => {
  return apolloClient.mutate({
    mutation: SET_PASSWORD,
    variables: { password, token },
  });
};
