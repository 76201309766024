import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '~/store/store';

interface IPermissionProps {
  roles?: string[];
  userRoles?: string[];
  children?: React.ReactNode;
}

const Permission = (props: IPermissionProps): React.ReactElement | null => {
  const userRoles = useSelector(
    (state: RootState) =>
      state.auth?.userInfo?.userRoles || props.userRoles || [],
  );

  if (props.roles) {
    const listRoles = userRoles;
    if (props.roles.some((role) => listRoles.includes(role))) {
      return <>{props.children}</>;
    }

    return null;
  }

  return <>{props.children}</>;
};

export default Permission;
