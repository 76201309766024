import { AliasToken } from 'antd/es/theme/internal';

const token: Partial<AliasToken> = {
  colorLink: '#7E6DD6',
  colorLinkHover: '#7E6DD6',
  borderRadius: 2,
  colorPrimary: '#7E6DD6',
};

export default token;
