import { apolloClient } from '~/apollo';
import { DEFAULT_PER_PAGE, ServiceType } from '~/constants/common';
import {
  CREATE_AGE_GLYCATION,
  CREATE_BLOOD_GLUCOSE,
  CREATE_BLOOD_PRESSURE,
  CREATE_CAPILLARY_TYPE,
  CREATE_CUSTOM_SERVICE,
  CREATE_EXERCISE_MANAGEMENT,
  CREATE_GRIP_STRENGTH,
  CREATE_PULSWAVE,
  DELETE_AGE_GLYCATION,
  DELETE_BLOOD_GLUCOSE,
  DELETE_BLOOD_PRESSURE,
  DELETE_CAPILLARY_TYPE,
  DELETE_CUSTOM_SERVICE,
  DELETE_EXERCISE_MANAGEMENT,
  DELETE_GRIP_STRENGTH,
  DELETE_PULSWAVE,
} from '~/graphql/mutations';
import {
  GET_HISTORY_CALORIE,
  GET_HISTORY_EXERCISE,
  GET_HISTORY_FATIGUE,
  GET_HISTORY_RUNNING,
  GET_HISTORY_SLEEP,
  GET_HISTORY_STAND,
  GET_HISTORY_STEP,
  GET_HISTORY_WALKING,
  HISTORY_AGE_GLYCATION,
  HISTORY_BLOOD_GLUCOSE,
  HISTORY_BLOOD_PRESSURE,
  HISTORY_CAPILLARY_TYPE,
  HISTORY_CUSTOM_SERVICE,
  HISTORY_EXERCISE_MANAGEMENT,
  HISTORY_GRIP_STRENGTH,
  HISTORY_HEART_RATE,
  HISTORY_PULSWAVE,
} from '~/graphql/queries';
import {
  CreateAgeGlycationInput,
  CreateBloodGlucoseInput,
  CreateBloodPressureInput,
  CreateCapillaryTypeInput,
  CreateExerciseManagementInput,
  CreateGripStrengthInput,
  CreatePulseWaveInput,
  UserServiceRecordInput,
} from '~/models/common';

export const getHistoryExerciseManagement = async ({
  userId,
  pagination,
}): Promise<any> => {
  return apolloClient.query({
    query: HISTORY_EXERCISE_MANAGEMENT,
    variables: {
      userId,
      pagination: {
        page: pagination?.page || 1,
        perPage: DEFAULT_PER_PAGE,
      },
    },
  });
};

export const getHistoryAgeGlycation = async ({
  userId,
  pagination,
}): Promise<any> => {
  return apolloClient.query({
    query: HISTORY_AGE_GLYCATION,
    variables: {
      userId,
      pagination: {
        page: pagination?.page || 1,
        perPage: DEFAULT_PER_PAGE,
      },
    },
  });
};

export const getHistoryBloodGlucose = async ({
  userId,
  pagination,
}): Promise<any> => {
  return apolloClient.query({
    query: HISTORY_BLOOD_GLUCOSE,
    variables: {
      userId,
      pagination: {
        page: pagination?.page || 1,
        perPage: DEFAULT_PER_PAGE,
      },
    },
  });
};

export const getHistoryPulseWave = async ({
  userId,
  pagination,
}): Promise<any> => {
  return apolloClient.query({
    query: HISTORY_PULSWAVE,
    variables: {
      userId,
      pagination: {
        page: pagination?.page || 1,
        perPage: DEFAULT_PER_PAGE,
      },
    },
  });
};

export const getHistoryGripStrength = async ({
  userId,
  pagination,
}): Promise<any> => {
  return apolloClient.query({
    query: HISTORY_GRIP_STRENGTH,
    variables: {
      userId,
      pagination: {
        page: pagination?.page || 1,
        perPage: DEFAULT_PER_PAGE,
      },
    },
  });
};

export const getHistoryBloodPressure = async ({
  userId,
  pagination,
}): Promise<any> => {
  return apolloClient.query({
    query: HISTORY_BLOOD_PRESSURE,
    variables: {
      userId,
      pagination: {
        page: pagination?.page || 1,
        perPage: DEFAULT_PER_PAGE,
      },
    },
  });
};

export const getHistoryCapillaryType = async ({
  userId,
  pagination,
}): Promise<any> => {
  return apolloClient.query({
    query: HISTORY_CAPILLARY_TYPE,
    variables: {
      userId,
      pagination: {
        page: pagination?.page || 1,
        perPage: DEFAULT_PER_PAGE,
      },
    },
  });
};

export const getHistoryCustomService = async ({
  serviceId,
  userId,
  pagination,
}): Promise<any> => {
  return apolloClient.query({
    query: HISTORY_CUSTOM_SERVICE,
    variables: {
      data: {
        serviceId,
        userId,
      },
      pagination: {
        page: pagination?.page || 1,
        perPage: DEFAULT_PER_PAGE,
      },
    },
  });
};

export const deleteHistory = async (
  type: ServiceType,
  id: string,
): Promise<any> => {
  switch (type) {
    case ServiceType.ExerciseManagement:
      return apolloClient.mutate({
        mutation: DELETE_EXERCISE_MANAGEMENT,
        variables: {
          id,
        },
      });
    case ServiceType.AgeGlycation:
      return apolloClient.mutate({
        mutation: DELETE_AGE_GLYCATION,
        variables: {
          id,
        },
      });
    case ServiceType.BloodGlucose:
      return apolloClient.mutate({
        mutation: DELETE_BLOOD_GLUCOSE,
        variables: {
          id,
        },
      });
    case ServiceType.BloodPressure:
      return apolloClient.mutate({
        mutation: DELETE_BLOOD_PRESSURE,
        variables: {
          id,
        },
      });
    case ServiceType.CapillaryType:
      return apolloClient.mutate({
        mutation: DELETE_CAPILLARY_TYPE,
        variables: {
          id,
        },
      });
    case ServiceType.GripStrength:
      return apolloClient.mutate({
        mutation: DELETE_GRIP_STRENGTH,
        variables: {
          id,
        },
      });
    case ServiceType.PulseWave:
      return apolloClient.mutate({
        mutation: DELETE_PULSWAVE,
        variables: {
          id,
        },
      });

    default:
      return apolloClient.mutate({
        mutation: DELETE_CUSTOM_SERVICE,
        variables: {
          id,
        },
      });
      break;
  }
};

export const createExerciseManagement = (
  data: CreateExerciseManagementInput,
): Promise<any> => {
  return apolloClient.mutate({
    mutation: CREATE_EXERCISE_MANAGEMENT,
    variables: {
      data,
    },
  });
};

export const createAgeGlycation = (
  data: CreateAgeGlycationInput,
): Promise<any> => {
  return apolloClient.mutate({
    mutation: CREATE_AGE_GLYCATION,
    variables: {
      data,
    },
  });
};

export const createBloodGlucose = (
  data: CreateBloodGlucoseInput,
): Promise<any> => {
  return apolloClient.mutate({
    mutation: CREATE_BLOOD_GLUCOSE,
    variables: {
      data,
    },
  });
};

export const createPulseWave = (data: CreatePulseWaveInput): Promise<any> => {
  return apolloClient.mutate({
    mutation: CREATE_PULSWAVE,
    variables: {
      data,
    },
  });
};

export const createGripStrength = (
  data: CreateGripStrengthInput,
): Promise<any> => {
  return apolloClient.mutate({
    mutation: CREATE_GRIP_STRENGTH,
    variables: {
      data,
    },
  });
};

export const createBloodPressure = (
  data: CreateBloodPressureInput,
): Promise<any> => {
  return apolloClient.mutate({
    mutation: CREATE_BLOOD_PRESSURE,
    variables: {
      data,
    },
  });
};

export const createCapillaryType = (
  data: CreateCapillaryTypeInput,
): Promise<any> => {
  return apolloClient.mutate({
    mutation: CREATE_CAPILLARY_TYPE,
    variables: {
      data,
    },
  });
};

export const createCustomService = (
  data: UserServiceRecordInput,
): Promise<any> => {
  return apolloClient.mutate({
    mutation: CREATE_CUSTOM_SERVICE,
    variables: {
      data,
    },
  });
};

export const getHistoryHeartRate = async ({
  userId,
  from,
  to,
  mode,
}): Promise<any> => {
  return apolloClient.query({
    query: HISTORY_HEART_RATE,
    variables: {
      userId,
      from,
      to,
      mode,
    },
  });
};

export const getHistoryStep = async ({
  userId,
  from,
  to,
  mode,
}): Promise<any> => {
  return apolloClient.query({
    query: GET_HISTORY_STEP,
    variables: {
      userId,
      from,
      to,
      mode,
    },
  });
};

export const getHistoryExercise = async ({
  userId,
  from,
  to,
  mode,
}): Promise<any> => {
  return apolloClient.query({
    query: GET_HISTORY_EXERCISE,
    variables: {
      userId,
      from,
      to,
      mode,
    },
  });
};

export const getHistoryCalorie = async ({
  userId,
  from,
  to,
  mode,
}): Promise<any> => {
  return apolloClient.query({
    query: GET_HISTORY_CALORIE,
    variables: {
      userId,
      from,
      to,
      mode,
    },
  });
};

export const getHistoryStand = async ({
  userId,
  from,
  to,
  mode,
}): Promise<any> => {
  return apolloClient.query({
    query: GET_HISTORY_STAND,
    variables: {
      userId,
      from,
      to,
      mode,
    },
  });
};

export const getHistoryFatigue = async ({
  userId,
  pagination,
}): Promise<any> => {
  return apolloClient.query({
    query: GET_HISTORY_FATIGUE,
    variables: {
      userId,
      pagination: {
        page: pagination?.page || 1,
        perPage: DEFAULT_PER_PAGE,
      },
    },
  });
};

export const getHistoryRunning = async ({
  userId,
  pagination,
  from,
  to,
}): Promise<any> => {
  return apolloClient.query({
    query: GET_HISTORY_RUNNING,
    variables: {
      from,
      to,
      userId,
      pagination: {
        page: pagination?.page || 1,
        perPage: DEFAULT_PER_PAGE,
      },
    },
  });
};

export const getHistoryWalking = async ({
  userId,
  pagination,
  from,
  to,
}): Promise<any> => {
  return apolloClient.query({
    query: GET_HISTORY_WALKING,
    variables: {
      from,
      to,
      userId,
      pagination: {
        page: pagination?.page || 1,
        perPage: DEFAULT_PER_PAGE,
      },
    },
  });
};

export const getHistorySleep = async ({ userId, from, to }): Promise<any> => {
  return apolloClient.query({
    query: GET_HISTORY_SLEEP,
    variables: {
      from,
      to,
      userId,
    },
  });
};
