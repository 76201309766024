import { apolloClient } from '~/apollo';
import { DEFAULT_PER_PAGE } from '~/constants/common';
import {
  CREATE_MET_TYPE,
  DELETE_MET_TYPE,
  UPDATE_MET_TYPE,
} from '~/graphql/mutations';
import { LIST_MET_TYPE } from '~/graphql/queries';
import {
  ListMetTypeResponse,
  MetTypeInput,
  MutationDeleteMetTypeArgs,
  MutationUpdateMetTypeArgs,
  QueryListMetTypeArgs,
} from '~/models/common';

export const listMetTypeService = async ({
  pagination,
  where,
  orderBy,
}: QueryListMetTypeArgs): Promise<ListMetTypeResponse> => {
  const res = await apolloClient.query({
    query: LIST_MET_TYPE,
    variables: {
      pagination: {
        page: pagination?.page || 1,
        perPage: DEFAULT_PER_PAGE,
      },
      where,
      orderBy,
    },
  });
  return res.data.listMetType;
};

export const getMetTypeService = async (
  id: string,
): Promise<ListMetTypeResponse> => {
  const res = await apolloClient.query({
    query: LIST_MET_TYPE,
    variables: {
      where: {
        id: {
          equals: id,
        },
      },
    },
  });
  return res.data.listMetType;
};

export const createMetType = async (payload: MetTypeInput): Promise<any> => {
  return apolloClient.mutate({
    mutation: CREATE_MET_TYPE,
    variables: {
      data: {
        ...payload,
      },
    },
  });
};

export const updateMetType = async (
  payload: MutationUpdateMetTypeArgs,
): Promise<any> => {
  return apolloClient.mutate({
    mutation: UPDATE_MET_TYPE,
    variables: {
      ...payload,
    },
  });
};

export const deleteMetType = async (
  payload: MutationDeleteMetTypeArgs,
): Promise<any> => {
  return apolloClient.mutate({
    mutation: DELETE_MET_TYPE,
    variables: {
      ...payload,
    },
  });
};
