import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  NotificationDetailResponse,
  NotificationInput,
  NotificationResponse,
  NotificationTarget,
  NotificationWhereInput,
  PaginationInput,
  PaginationPageInfo,
} from '~/models/common';
import {
  createNotificationService,
  deleteNotificationService,
  fetchDetailNotificationService,
  listNotificationService,
  updateNotificationService,
} from '~/services/notificationService';
import { thunkAction } from '~/utils/utils';

interface INotificationState {
  listNotification: {
    data: NotificationResponse[];
    pagination: PaginationPageInfo;
  };
  listUserNotification: {
    data: NotificationResponse[];
    pagination: PaginationPageInfo;
  };
  detailNotification: NotificationDetailResponse;
  error: any;
  loading: boolean;
}

const initialState: INotificationState = {
  listNotification: {
    data: [],
    pagination: {},
  } as any,
  listUserNotification: {
    data: [],
    pagination: {},
  } as any,
  detailNotification: null as any,
  error: {},
  loading: false,
};

export const fetchListNotification = createAsyncThunk(
  'notification/list',
  thunkAction(
    async (payload: {
      target: NotificationTarget;
      pagination: PaginationInput;
      where?: NotificationWhereInput;
    }) => {
      return listNotificationService(payload);
    },
  ),
);

export const postCreateNotification = createAsyncThunk(
  'notification/create',
  thunkAction(
    async (payload: {
      target: NotificationTarget;
      data: NotificationInput;
    }) => {
      return createNotificationService(payload);
    },
  ),
);

export const fetchDetailNotification = createAsyncThunk(
  'notification/detail',
  thunkAction(async (id: string) => {
    return fetchDetailNotificationService(id);
  }),
);

export const postDeleteNotification = createAsyncThunk(
  'notification/delete',
  thunkAction(async (ids: string[]) => {
    return deleteNotificationService(ids);
  }),
);

export const postUpdateNotification = createAsyncThunk(
  'notification/update',
  thunkAction(async (payload: { id: string; data: NotificationInput }) => {
    return updateNotificationService(payload);
  }),
);

const NotificationSlice = createSlice({
  initialState,
  name: 'notification',
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchListNotification.fulfilled, (state, action) => {
      const target = action?.meta?.arg?.target;

      if (target === NotificationTarget.Company) {
        return {
          ...state,
          listNotification: {
            data: [...action.payload.data],
            pagination: { ...action.payload.pagination },
          },
          error: {},
          loading: false,
        };
      }

      return {
        ...state,
        listUserNotification: {
          data: [...action.payload.data],
          pagination: { ...action.payload.pagination },
        },
        error: {},
        loading: false,
      };
    });
    builder.addCase(fetchDetailNotification.fulfilled, (state, action) => ({
      ...state,
      detailNotification: action.payload,
      error: {},
      loading: false,
    }));
    builder.addMatcher(
      isAnyOf(
        postCreateNotification.fulfilled,
        postDeleteNotification.fulfilled,
        postUpdateNotification.fulfilled,
      ),
      (state) => ({
        ...state,
        error: {},
        loading: false,
      }),
    );
    builder.addMatcher(
      isAnyOf(
        fetchListNotification.pending,
        postCreateNotification.pending,
        fetchDetailNotification.pending,
        postDeleteNotification.pending,
        postUpdateNotification.pending,
      ),
      (state) => ({
        ...state,
        loading: true,
      }),
    );
    builder.addMatcher(
      isAnyOf(
        fetchListNotification.rejected,
        postCreateNotification.rejected,
        fetchDetailNotification.rejected,
        postDeleteNotification.rejected,
        postUpdateNotification.rejected,
      ),
      (state, action) => ({
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      }),
    );
  },
});

export default NotificationSlice.reducer;
