import { Form, Input, Modal, Skeleton, Space, Typography, Upload } from 'antd';
import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NOTIFY_TYPE } from '~/constants/common';
import { getUploadUrl, uploadS3File } from '~/services/commonService';
import { setIsOpenProfile } from '~/store/slices/common';
import { postUpdateProfile } from '~/store/slices/company';
import { AppDispatch, RootState } from '~/store/store';
import { handleError, notify } from '~/utils/utils';
import styles from './index.module.scss';

const { Title, Text } = Typography;

const cx = classNames.bind(styles);
const ProfileDetailView = (): React.ReactElement => {
  const isOpneModal = useSelector(
    (store: RootState) => store.common.isOpneProfile,
  );
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const userInfo = useSelector((store: RootState) => store.auth.userInfo);
  const loading = useSelector((store: RootState) => store.company?.loading);
  const [form] = Form.useForm();
  const [previewImage, setPreviewImage] = useState<any>();
  const [avatar, setAvatar] = useState<any>();
  const [loadingImage, setLoadingImage] = useState(false);

  const handleSubmit = async (values): Promise<void> => {
    const userAvatar = userInfo?.avatar === avatar ? undefined : avatar;
    const result: any = await dispatch(
      postUpdateProfile({
        ...values,
        avatar: avatar || previewImage ? userAvatar : null,
      }),
    );

    if (!result?.error) {
      notify(NOTIFY_TYPE.SUCCESS, t('company.update.success')!);

      window.location.reload();
    } else {
      handleError(result, t('common.error'));
    }
  };

  const handleRemove = (): void => {
    setAvatar(undefined);
    setPreviewImage(undefined);
  };

  const handleUpload = async (file: any): Promise<void> => {
    try {
      setLoadingImage(true);
      const { data } = await getUploadUrl(file?.file.name, file?.file.type);
      await uploadS3File(data?.getUploadUrl.url, file.file);
      setAvatar(data?.getUploadUrl.fileName);
    } catch (_) {
      notify(NOTIFY_TYPE.ERROR, 'Upload image failed');
    } finally {
      setLoadingImage(false);
    }
  };

  const handleChangeFile = (data: any): void => {
    if (data.file.originFileObj) {
      const image = URL.createObjectURL(data.file.originFileObj);
      setPreviewImage(image);
    }
  };

  const handleBeforeUpload = (file): any => {
    if (!file.type.includes('image')) {
      notify(NOTIFY_TYPE.ERROR, 'Image only');
      return false;
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      name: userInfo?.name,
    });
    setAvatar(userInfo?.avatar);
  }, [userInfo, isOpneModal]);

  return (
    <Modal
      title={t('company.my-page.title')}
      open={isOpneModal}
      onCancel={(): void => {
        setPreviewImage(undefined);
        dispatch(setIsOpenProfile(false));
      }}
      okText={t('company.my-page.ok-text')}
      cancelText={t('company.my-page.cancel-text')}
      onOk={(): void => form.submit()}
      confirmLoading={loading}
      okButtonProps={{
        disabled: loadingImage,
      }}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Space direction="vertical" size="small">
            <Title className={cx('title')} level={5}>
              {t('company.my-page.company-id')}
            </Title>
            <Text>{userInfo?.companyId}</Text>
          </Space>
          <Space direction="vertical" size="small">
            <Title className={cx('title')} level={5}>
              {t('company.my-page.email')}
            </Title>
            <Text>{userInfo?.email}</Text>
          </Space>
          <Space direction="vertical" size="small" style={{ width: '100%' }}>
            <Title className={cx('title')} level={5}>
              {t('company.my-page.account-name')}
            </Title>
            <Form.Item className={cx('form-item')} name="name">
              <Input />
            </Form.Item>
          </Space>
          <Space direction="vertical" size="small">
            <Title className={cx('title')} level={5}>
              {t('company.my-page.image')}
            </Title>
            <Upload
              showUploadList={false}
              beforeUpload={handleBeforeUpload}
              onChange={handleChangeFile}
              customRequest={handleUpload}
            >
              {!previewImage && !avatar && (
                <Text className={cx('text-upload')}>
                  {t('company.my-page.choose-image')}
                </Text>
              )}
            </Upload>
            {(previewImage || avatar) && (
              <>
                {loadingImage ? (
                  <Skeleton.Image active={true} />
                ) : (
                  <img
                    style={{
                      height: '150px',
                      objectFit: 'contain',
                    }}
                    src={previewImage || avatar}
                    alt=""
                  />
                )}
                <Text onClick={handleRemove} className={cx('text-remove')}>
                  {t('company.my-page.remove-image')}
                </Text>
              </>
            )}
          </Space>
        </Space>
      </Form>
    </Modal>
  );
};

export default ProfileDetailView;
