import { apolloClient } from '~/apollo';
import { LOGIN } from '~/graphql/mutations';
import { GET_USER_INFO } from '~/graphql/queries';

export const loginService = async (
  email: string,
  password: string,
  captchaToken: string,
  userGroup: string,
): Promise<any> => {
  return apolloClient.mutate({
    mutation: LOGIN,
    variables: { email, password, captchaToken, userGroup },
  });
};

export const getUserInfoService = async (): Promise<any> => {
  return apolloClient.query({
    query: GET_USER_INFO,
  });
};
