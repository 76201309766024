import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  DetailEventResponse,
  Event,
  MutationCreateEventArgs,
  MutationDeleteEventArgs,
  MutationUpdateEventArgs,
  PaginationPageInfo,
  QueryDetailEventArgs,
  QueryListEventsArgs,
  QueryListUserJoinedEventArgs,
  UsersJoinedEventResponse,
} from '~/models/common';
import {
  createEvent,
  deleteEvent,
  getEventService,
  listEventService,
  listUserJoinedEventService,
  updateEvent,
} from '~/services/eventService';
import { thunkAction } from '~/utils/utils';

interface IState {
  listEvent: {
    data: Event[];
    pagination: PaginationPageInfo;
  };
  detailEvent: DetailEventResponse;
  error: object;
  loading: boolean;
  userJoined?: UsersJoinedEventResponse;
}

const initialState: IState = {
  listEvent: {
    data: [],
    pagination: {},
  } as any,
  detailEvent: {} as DetailEventResponse,
  error: {},
  loading: false,
};

export const fetchListEvent = createAsyncThunk(
  'event/list',
  thunkAction(async (payload: QueryListEventsArgs) => {
    const res = await listEventService(payload);
    return res;
  }),
);

export const fetchDetailEvent = createAsyncThunk(
  'event/detail',
  thunkAction(async (payload: QueryDetailEventArgs) => {
    const res = await getEventService(payload);
    return res;
  }),
);

export const postCreateEvent = createAsyncThunk(
  'event/create',
  thunkAction((payload: MutationCreateEventArgs) => {
    return createEvent(payload);
  }),
);

export const postUpdateEvent = createAsyncThunk(
  'event/update',
  thunkAction((payload: MutationUpdateEventArgs) => {
    return updateEvent(payload);
  }),
);

export const postDeleteEvent = createAsyncThunk(
  'event/delete',
  thunkAction((payload: MutationDeleteEventArgs) => {
    return deleteEvent(payload);
  }),
);

export const fetchListUserJoinedEvent = createAsyncThunk(
  'event/list-user',
  thunkAction(async (payload: QueryListUserJoinedEventArgs) => {
    const res = await listUserJoinedEventService(payload);
    return res;
  }),
);

const EventSlice = createSlice<IState, any, string>({
  name: 'event',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchListEvent.fulfilled, (state, action) => {
      return {
        ...state,
        listEvent: {
          data: [...action.payload.events],
          pagination: { ...action.payload.pagination },
        },
        error: {},
        loading: false,
      };
    });
    builder.addCase(fetchDetailEvent.fulfilled, (state, action) => {
      return {
        ...state,
        detailEvent: { ...action.payload },
        error: {},
        loading: false,
      };
    });
    builder.addCase(fetchListUserJoinedEvent.fulfilled, (state, action) => {
      return {
        ...state,
        userJoined: { ...action.payload },
        error: {},
        loading: false,
      };
    });
    builder.addMatcher(
      isAnyOf(
        postCreateEvent.fulfilled,
        postUpdateEvent.fulfilled,
        postDeleteEvent.fulfilled,
      ),
      (state) => ({
        ...state,
        error: {},
        loading: false,
      }),
    );
    builder.addMatcher(
      isAnyOf(
        fetchListEvent.pending,
        fetchDetailEvent.pending,
        postCreateEvent.pending,
        postUpdateEvent.pending,
        postDeleteEvent.pending,
        fetchListUserJoinedEvent.pending,
      ),
      (state) => ({
        ...state,
        loading: true,
      }),
    );
    builder.addMatcher(
      isAnyOf(
        fetchListEvent.rejected,
        fetchDetailEvent.rejected,
        postCreateEvent.rejected,
        postUpdateEvent.rejected,
        postDeleteEvent.rejected,
        fetchListUserJoinedEvent.rejected,
      ),
      (state, action) => ({
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      }),
    );
  },
});

export const { setStatus } = EventSlice.actions;
export default EventSlice.reducer;
