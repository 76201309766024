import { Spin } from 'antd';
import classNames from 'classnames/bind';
import React, { CSSProperties } from 'react';
import styles from './index.module.scss';

interface LoadingProps {
  loading: boolean;
  styleProp?: CSSProperties;
}

const cx = classNames.bind(styles);
const LoadingView = ({
  loading,
  styleProp = {},
}: LoadingProps): React.ReactElement => {
  return (
    <div
      className={cx({ display: loading, 'display-none': !loading })}
      style={styleProp}
    >
      <Spin />
    </div>
  );
};

export default LoadingView;
