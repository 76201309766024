import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import forgotPasswordReducer from './slices/auth/forgotPassword';
import loginReducer from './slices/auth/login';
import SetPasswordReducer from './slices/auth/setPassword';
import verifyTokenReducer from './slices/auth/verifyToken';
import commonReducer from './slices/common';
import companyReducer from './slices/company';
import memberReducer from './slices/member';
import metTypeReducer from './slices/metType';
import notificationReducer from './slices/notification';
import serviceReducer from './slices/service';
import userReducer from './slices/user';
import graphReducer from './slices/graph';
import historyReducer from './slices/history';
import eventReducer from './slices/event';
import questionnaireReducer from './slices/questionnaire';
import healthCheckReducer from './slices/healthCheck';

export const store = configureStore({
  reducer: {
    common: commonReducer,
    auth: loginReducer,
    setPassword: SetPasswordReducer,
    verifyPassword: verifyTokenReducer,
    user: userReducer,
    member: memberReducer,
    company: companyReducer,
    forgotPassword: forgotPasswordReducer,
    metType: metTypeReducer,
    notification: notificationReducer,
    service: serviceReducer,
    graph: graphReducer,
    history: historyReducer,
    event: eventReducer,
    questionnaire: questionnaireReducer,
    healthCheck: healthCheckReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
