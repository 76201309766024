import React from 'react';
import { ReactComponent as CompanyIcon } from '~/assets/icons/company.svg';
import { ReactComponent as InfoIcon } from '~/assets/icons/info.svg';
import { ReactComponent as MetTypeIcon } from '~/assets/icons/met-type.svg';
import { ReactComponent as ServiceIcon } from '~/assets/icons/service.svg';
import { ReactComponent as SettingIcon } from '~/assets/icons/setting.svg';
import { ReactComponent as UserGroupIcon } from '~/assets/icons/user-group.svg';
// import { ReactComponent as MetTypeIcon } from '~/assets/icons/met-type.svg';
// import { ReactComponent as ServiceIcon } from '~/assets/icons/service.svg';
import { ReactComponent as EventIcon } from '~/assets/icons/event.svg';
import { ReactComponent as QuestionnaireIcon } from '~/assets/icons/questionnaire.svg';
import { ReactComponent as TargetIcon } from '~/assets/icons/target.svg';
import { USER_ROLE } from '~/constants/common';
import PATH from '~/constants/path';
import i18n from '~/i18n';

export type MenuItem = {
  title: string;
  icon?: React.ReactNode;
  path?: string;
  roles?: string[];
  children?: MenuItem[];
  disableForDummyCompany?: boolean;
};

const MENU: MenuItem[] = [
  {
    title: `${i18n.t('common.menu.manage-noti')}`,
    icon: <InfoIcon />,
    children: [
      {
        title: i18n.t('common.menu.list-noti-company'),
        path: PATH.system.notification.company.list,
        roles: [USER_ROLE.SYSTEM_ADMIN],
      },
      {
        title: i18n.t('common.menu.list-noti-company-from-system'),
        path: PATH.company.notification.company.list,
        roles: [USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER],
      },
      {
        title: i18n.t('common.menu.create-noti-company'),
        path: PATH.system.notification.company.create,
        roles: [USER_ROLE.SYSTEM_ADMIN],
      },
      {
        title: i18n.t('common.menu.list-noti-user'),
        path: `${PATH.system.notification.user.list}`,
        roles: [USER_ROLE.SYSTEM_ADMIN],
      },
      {
        title: i18n.t('common.menu.create-noti-user'),
        path: `${PATH.system.notification.user.create}`,
        roles: [USER_ROLE.SYSTEM_ADMIN],
      },
      {
        title: i18n.t('common.menu.list-noti-user'),
        path: PATH.company.notification.user.list,
        roles: [USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER],
      },
      {
        title: i18n.t('common.menu.create-noti-user'),
        path: PATH.company.notification.user.create,
        roles: [USER_ROLE.COMPANY],
      },
    ],
  },
  {
    title: `${i18n.t('common.menu.manage-user')}`,
    icon: <UserGroupIcon />,
    children: [
      {
        title: i18n.t('common.menu.list-user'),
        path: PATH.company.user.list,
        roles: [USER_ROLE.COMPANY],
      },
      {
        title: i18n.t('common.menu.register-user'),
        path: PATH.company.user.create,
        roles: [USER_ROLE.COMPANY],
      },
      {
        title: i18n.t('common.menu.list-user'),
        path: PATH.system.user.list,
        roles: [USER_ROLE.SYSTEM_ADMIN],
      },
    ],
    roles: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.COMPANY],
  },
  {
    title: `${i18n.t('common.menu.manage-business')}`,
    icon: <CompanyIcon />,
    children: [
      {
        title: i18n.t('common.menu.list-company'),
        path: PATH.system.company.list,
      },
      {
        title: i18n.t('common.menu.create-company'),
        path: PATH.system.company.create,
      },
    ],
    roles: [USER_ROLE.SYSTEM_ADMIN],
  },
  {
    title: `${i18n.t('common.menu.service.title')}`,
    icon: <ServiceIcon />,
    children: [
      {
        title: i18n.t('common.menu.service.list'),
        path: PATH.system.service.list,
        roles: [USER_ROLE.SYSTEM_ADMIN],
      },
      {
        title: i18n.t('common.menu.service.list'),
        path: PATH.company.service.list,
        roles: [USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER],
      },
      {
        title: i18n.t('common.menu.service.create'),
        path: PATH.system.service.create,
        roles: [USER_ROLE.SYSTEM_ADMIN],
      },
    ],
  },
  {
    title: `${i18n.t('common.menu.manage-metType')}`,
    icon: <MetTypeIcon />,
    children: [
      {
        title: i18n.t('common.menu.list-metType'),
        path: PATH.metType.company.list,
        roles: [USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER],
      },
      {
        title: i18n.t('common.menu.list-metType'),
        path: PATH.metType.system.list,
        roles: [USER_ROLE.SYSTEM_ADMIN],
      },
      {
        title: i18n.t('common.menu.create-metType'),
        path: PATH.metType.system.create,
        roles: [USER_ROLE.SYSTEM_ADMIN],
      },
    ],
  },
  {
    title: `${i18n.t('common.menu.manage-event')}`,
    icon: <EventIcon />,
    children: [
      {
        title: i18n.t('common.menu.list-event'),
        path: PATH.event.system.list,
        roles: [USER_ROLE.SYSTEM_ADMIN],
      },
      {
        title: i18n.t('common.menu.list-event'),
        path: PATH.event.company.list,
        roles: [USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER],
      },
      {
        title: i18n.t('common.menu.create-event'),
        path: PATH.event.company.create,
        roles: [USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER],
      },
      {
        title: i18n.t('common.menu.create-event'),
        path: PATH.event.system.create,
        roles: [USER_ROLE.SYSTEM_ADMIN],
      },
    ],
  },
  {
    title: i18n.t('common.menu.target.title'),
    icon: <TargetIcon />,
    children: [
      {
        title: i18n.t('common.menu.target.list'),
        path: PATH.company.target.list,
      },
      {
        title: i18n.t('common.menu.target.create'),
        path: PATH.company.target.create,
      },
    ],
    roles: [USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER],
  },
  {
    title: `${i18n.t('common.menu.healthCheck')}`,
    icon: <QuestionnaireIcon />,
    children: [
      {
        title: i18n.t('common.menu.list-healthCheck'),
        path: PATH.system.healthCheck.list,
        roles: [USER_ROLE.SYSTEM_ADMIN],
      },
      {
        title: i18n.t('common.menu.list-healthCheck'),
        path: PATH.company.healthCheck.list,
        roles: [USER_ROLE.COMPANY],
      },
      {
        title: i18n.t('common.menu.create-healthCheck'),
        path: PATH.system.healthCheck.create,
        roles: [USER_ROLE.SYSTEM_ADMIN],
      },
      {
        title: i18n.t('common.menu.create-healthCheck'),
        path: PATH.company.healthCheck.create,
        roles: [USER_ROLE.COMPANY],
      },
    ],
    roles: [USER_ROLE.COMPANY, USER_ROLE.SYSTEM_ADMIN],
  },
  {
    title: `${i18n.t('common.menu.questionnaire')}`,
    icon: <QuestionnaireIcon />,
    children: [
      {
        title: i18n.t('common.menu.list-questionnaire'),
        path: PATH.system.questionnaire.list,
        roles: [USER_ROLE.SYSTEM_ADMIN],
      },
      {
        title: i18n.t('common.menu.list-questionnaire'),
        path: PATH.company.questionnaire.list,
        roles: [USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER],
      },
      {
        title: i18n.t('common.menu.create-questionnaire'),
        path: PATH.system.questionnaire.create,
        roles: [USER_ROLE.SYSTEM_ADMIN],
      },
      {
        title: i18n.t('common.menu.create-questionnaire'),
        path: PATH.company.questionnaire.create,
        roles: [USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER],
      },
    ],
  },
  {
    title: `${i18n.t('common.menu.setting')}`,
    icon: <SettingIcon />,
    children: [
      {
        title: i18n.t('common.menu.corporate-info'),
        path: PATH.company.setting.index,
      },
      {
        title: i18n.t('common.menu.list-department'),
        path: PATH.company.setting.department.list,
        disableForDummyCompany: true,
      },
    ],
    roles: [USER_ROLE.COMPANY],
  },
];

export default MENU;
