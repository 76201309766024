// eslint-disable-next-line import/no-cycle
import { apolloClient } from '~/apollo';
import {
  CREATE_QUESTIONNAIRE,
  DELETE_QUESTIONNAIRE,
  DETAIL_USER_ANSWER_QUESTIONNAIRE,
  UPDATE_QUESTIONNAIRE,
} from '~/graphql/mutations';
import {
  DETAIL_QUESTIONNAIRE,
  EXPORT_CSV_QUESTIONNAIRE,
  LIST_QUESTIONNAIRE,
} from '~/graphql/queries';
import {
  CreateQuestionnaireInput,
  DeleteQuestionnaireInput,
  DetailUserAnswerQuestionnaireInput,
  ExportQuestionnaireInput,
  ExportQuestionnaireResponse,
  PaginationInput,
  QuestionnaireOrderByInput,
  QuestionnaireResponse,
  QuestionnaireWhereInput,
  UpdateQuestionnaireInput,
} from '~/models/common';

export const createQuestionnaireService = async (
  data: CreateQuestionnaireInput,
): Promise<any> => {
  return apolloClient.mutate({
    mutation: CREATE_QUESTIONNAIRE,
    variables: { data },
  });
};
export const updateQuestionnaireService = async (
  data: UpdateQuestionnaireInput,
): Promise<any> => {
  return apolloClient.mutate({
    mutation: UPDATE_QUESTIONNAIRE,
    variables: { data },
  });
};

export const listQuestionnaireService = async (
  where?: QuestionnaireWhereInput,
  pagination?: PaginationInput,
  orderBy?: QuestionnaireOrderByInput,
): Promise<any> => {
  const res = await apolloClient.query({
    query: LIST_QUESTIONNAIRE,
    variables: {
      where: {
        ...where,
      },
      pagination,
      orderBy,
    },
  });
  return res?.data?.listQuestionnaire;
};

export const fetchDetailQuestionnaireService = async (
  id: string,
): Promise<QuestionnaireResponse> => {
  const res = await apolloClient.query({
    query: DETAIL_QUESTIONNAIRE,
    variables: {
      detailQuestionnaireId: id,
    },
  });
  return res?.data?.detailQuestionnaire;
};

export const deleteQuestionnaireService = async (
  data: DeleteQuestionnaireInput,
): Promise<any> => {
  const res = await apolloClient.mutate({
    mutation: DELETE_QUESTIONNAIRE,
    variables: { data },
  });
  return res?.data?.deleteQuestionnaire;
};

export const fetchDetailUserAnswerQuestionnaireService = async (
  data: DetailUserAnswerQuestionnaireInput,
): Promise<any> => {
  const res = await apolloClient.mutate({
    mutation: DETAIL_USER_ANSWER_QUESTIONNAIRE,
    variables: { data },
  });

  return res?.data?.detailUserAnswerQuestionnaire;
};

export const fetchExportCsvQuestionnaireService = async (
  data: ExportQuestionnaireInput,
): Promise<ExportQuestionnaireResponse> => {
  const res = await apolloClient.mutate({
    mutation: EXPORT_CSV_QUESTIONNAIRE,
    variables: { data },
  });

  return res?.data?.exportQuestionnaire;
};
