/* eslint-disable max-params */
import { apolloClient } from '~/apollo';
import { ServiceType } from '~/constants/common';
import {
  GRAPH_AGE_GLYCATION,
  GRAPH_BLOOD_GLUCOSE,
  GRAPH_BLOOD_PRESSURE,
  GRAPH_CAPILLARY_TYPE,
  GRAPH_EXERCISE_MANAGEMENT,
  GRAPH_GRIP_STRENGTH,
  GRAPH_PULSEWAVE,
  GRAPH_CUSTOM_SERVICE,
  GRAPH_HEART_RATE,
  GET_GRAPH_STEP,
  GET_GRAPH_EXERCISE,
  GET_GRAPH_CALORIE,
  GET_GRAPH_STAND,
  GET_GRAPH_FATIGUE,
  GET_GRAPH_SLEEP,
} from '~/graphql/queries';
import { ChunkUnitOfTime } from '~/models/common';

export const getGraphExerciseManagement = async (
  from: any,
  to: any,
  mode: ChunkUnitOfTime,
  userId: string,
): Promise<any> => {
  return apolloClient.query({
    query: GRAPH_EXERCISE_MANAGEMENT,
    variables: {
      from,
      to,
      mode,
      userId,
    },
  });
};

export const getGraph = async (
  from: any,
  to: any,
  mode: ChunkUnitOfTime,
  userId: string,
  type: ServiceType,
): Promise<any> => {
  switch (type) {
    case ServiceType.ExerciseManagement:
      return apolloClient.mutate({
        mutation: GRAPH_EXERCISE_MANAGEMENT,
        variables: {
          from,
          to,
          mode,
          userId,
        },
      });
    case ServiceType.AgeGlycation:
      return apolloClient.mutate({
        mutation: GRAPH_AGE_GLYCATION,
        variables: {
          from,
          to,
          mode,
          userId,
        },
      });
    case ServiceType.BloodGlucose:
      return apolloClient.mutate({
        mutation: GRAPH_BLOOD_GLUCOSE,
        variables: {
          from,
          to,
          mode,
          userId,
        },
      });
    case ServiceType.BloodPressure:
      return apolloClient.mutate({
        mutation: GRAPH_BLOOD_PRESSURE,
        variables: {
          from,
          to,
          mode,
          userId,
        },
      });
    case ServiceType.CapillaryType:
      return apolloClient.mutate({
        mutation: GRAPH_CAPILLARY_TYPE,
        variables: {
          from,
          to,
          mode,
          userId,
        },
      });
    case ServiceType.GripStrength:
      return apolloClient.mutate({
        mutation: GRAPH_GRIP_STRENGTH,
        variables: {
          from,
          to,
          mode,
          userId,
        },
      });
    case ServiceType.PulseWave:
      return apolloClient.mutate({
        mutation: GRAPH_PULSEWAVE,
        variables: {
          from,
          to,
          mode,
          userId,
        },
      });
    case ServiceType.HeartRate:
      return apolloClient.mutate({
        mutation: GRAPH_HEART_RATE,
        variables: {
          from,
          mode,
          to,
          userId,
        },
      });
    case ServiceType.Step:
      return apolloClient.mutate({
        mutation: GET_GRAPH_STEP,
        variables: {
          from,
          mode,
          to,
          userId,
        },
      });
    case ServiceType.Exercise:
      return apolloClient.mutate({
        mutation: GET_GRAPH_EXERCISE,
        variables: {
          from,
          mode,
          to,
          userId,
        },
      });
    case ServiceType.Calorie:
      return apolloClient.mutate({
        mutation: GET_GRAPH_CALORIE,
        variables: {
          from,
          mode,
          to,
          userId,
        },
      });
    case ServiceType.Stand:
      return apolloClient.mutate({
        mutation: GET_GRAPH_STAND,
        variables: {
          from,
          mode,
          to,
          userId,
        },
      });

    case ServiceType.Fatigue:
      return apolloClient.mutate({
        mutation: GET_GRAPH_FATIGUE,
        variables: {
          from,
          mode,
          to,
          userId,
        },
      });
    case ServiceType.Sleep:
      return apolloClient.mutate({
        mutation: GET_GRAPH_SLEEP,
        variables: {
          from,
          to,
          userId,
        },
      });
    default:
      return apolloClient.mutate({
        mutation: GRAPH_CUSTOM_SERVICE,
        variables: {
          data: {
            from,
            mode,
            serviceItemId: type,
            to,
            userId,
          },
        },
      });

      break;
  }
};
