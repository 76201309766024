import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  PaginationInput,
  PaginationPageInfo,
  UserResponse,
} from '~/models/common';
import {
  createMember,
  listMember,
  updateMember,
} from '~/services/memberService';
import { thunkAction } from '~/utils/utils';

const initialState = {
  listMember: {
    data: [] as UserResponse[],
    pagination: {} as PaginationPageInfo,
  },
  error: {},
  loading: false,
};

export const postCreateMember = createAsyncThunk(
  'member/create',
  thunkAction(({ name, email, authority }: any) => {
    return createMember(name, email, authority);
  }),
);

export const fetchListMember = createAsyncThunk(
  'member/list',
  thunkAction(async (payload: { pagination: PaginationInput; companyId }) => {
    const res = await listMember(payload);
    return res?.data?.listCompanyMember;
  }),
);

export const postUpdateMember = createAsyncThunk(
  'member/update',
  thunkAction(({ id, role, name }: any) => {
    return updateMember({ id, role, name });
  }),
);

const MemberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    setStatus(state, action) {
      return {
        ...state,
        status: action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(postCreateMember.fulfilled, (state) => ({
      ...state,
      loading: false,
    }));
    builder.addCase(fetchListMember.fulfilled, (state, action) => ({
      ...state,
      listMember: {
        data: [...action.payload.data],
        pagination: { ...action.payload.pagination },
      },
      loading: false,
    }));
    builder.addCase(postUpdateMember.fulfilled, (state) => ({
      ...state,
      loading: false,
    }));
    builder.addMatcher(
      isAnyOf(
        postCreateMember.pending,
        fetchListMember.pending,
        postUpdateMember.pending,
      ),
      (state) => ({
        ...state,
        loading: true,
      }),
    );
    builder.addMatcher(
      isAnyOf(
        postCreateMember.rejected,
        fetchListMember.rejected,
        postUpdateMember.rejected,
      ),
      (state, action) => ({
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      }),
    );
  },
});

export const { setStatus } = MemberSlice.actions;
export default MemberSlice.reducer;
