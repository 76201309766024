/* eslint-disable*/
import React, { lazy, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import LoadingView from '~/components/Loading';
import { DUMMY_COMPANY_ID, ROUTE, USER_ROLE } from '~/constants/common';
import PATH from '~/constants/path';
import MainLayout from '~/layouts/Layout';
import { NotificationTarget } from '~/models/common';
import { RootState } from '~/store/store';

// Route common
const SetPassword = lazy(() => import('~/pages/shared/SetPassword'));
const NotFound = lazy(() => import('~/components/NotFound'));
const PrivacyPolicy = lazy(() => import('~/pages/shared/PrivacyPolicy'));
const Terms = lazy(() => import('~/pages/shared/Terms'));
const DownloadApp = lazy(() => import('~/pages/shared/Download'));
const ListNotification = lazy(() => import('~/pages/shared/Notification/List'));
const CreateNotification = lazy(
  () => import('~/pages/shared/Notification/Create'),
);
const DetailNotification = lazy(
  () => import('~/pages/shared/Notification/Detail'),
);
const UpdateNotification = lazy(
  () => import('~/pages/shared/Notification/Update'),
);
// -----

const QuestionnaireCreate = lazy(
  () => import('~/pages/shared/Questionnaire/Create'),
);
const ListQuestionnaire = lazy(
  () => import('~/pages/shared/Questionnaire/List'),
);

const HealthCheckList = lazy(() => import('~/pages/shared/HealthCheck/List'));

const ListUserAnswer = lazy(
  () => import('~/pages/shared/HealthCheck/UsersAnswer'),
);
const UserAnswerDetail = lazy(
  () => import('~/pages/shared/HealthCheck/UserAnswerDetail'),
);
const HealthCheckCreate = lazy(
  () => import('~/pages/shared/HealthCheck/Create'),
);
const HealthCheckUpdate = lazy(() => import('~/pages/shared/HealthCheck/Edit'));

//Route system
const LoginSystem = lazy(() => import('~/pages/system/Login'));
const CreateCompanySystem = lazy(() => import('~/pages/system/Company/Create'));
const ListCompanySystem = lazy(() => import('~/pages/system/Company/List'));
const ListUserSystem = lazy(() => import('~/pages/shared/User/List'));
const DetailUserSystem = lazy(() => import('~/pages/shared/User/Detail'));
const UpdateUserSystem = lazy(() => import('~/pages/shared/User/Update'));
const DetailCompanySystem = lazy(() => import('~/pages/system/Company/Detail'));
const ListMetType = lazy(() => import('~/pages/system/MetType/List'));
const CreateMetType = lazy(() => import('~/pages/system/MetType/Create'));
const UpdateMetType = lazy(() => import('~/pages/system/MetType/Update'));
const UpdateCompanySystem = lazy(() => import('~/pages/system/Company/Update'));
const CreateService = lazy(() => import('~/pages/system/Service/Create'));
const ListService = lazy(() => import('~/pages/system/Service/List'));
const UpdateService = lazy(() => import('~/pages/system/Service/Update'));
const DetailService = lazy(() => import('~/pages/system/Service/Detail'));

const QuestionnaireDetail = lazy(
  () => import('~/pages/shared/Questionnaire/Detail'),
);
const QuestionnaireEdit = lazy(
  () => import('~/pages/shared/Questionnaire/Edit'),
);
//Route company
const LoginCompany = lazy(() => import('~/pages/company/Auth/Login'));
const ForgotMail = lazy(() => import('~/pages/company/Auth/ForgotMail'));
const CreateMember = lazy(() => import('~/pages/company/Member/Create'));
const CreateUser = lazy(() => import('~/pages/shared/User/Create'));
const ListUserCompany = lazy(() => import('~/pages/shared/User/List'));
const DetailUserCompany = lazy(() => import('~/pages/shared/User/Detail'));
const UpdateUserCompany = lazy(() => import('~/pages/shared/User/Update'));
const ForgotPasswordStep1 = lazy(
  () => import('~/pages/company/Auth/ForgotPassword/Step1'),
);
const ForgotPasswordStep2 = lazy(
  () => import('~/pages/company/Auth/ForgotPassword/Step2'),
);
const ForgotPasswordStep3 = lazy(
  () => import('~/pages/company/Auth/ForgotPassword/Step3'),
);
const ForgotPasswordStep4 = lazy(
  () => import('~/pages/company/Auth/ForgotPassword/Step4'),
);
const DetailCompanySetting = lazy(
  () => import('~/pages/company/Setting/Company/Detail'),
);
const UpdateCompanySetting = lazy(
  () => import('~/pages/company/Setting/Company/Update'),
);
const UpdateMember = lazy(() => import('~/pages/company/Member/Update'));

const ListDepartment = lazy(
  () => import('~/pages/company/Setting/Department/List'),
);

const CreateDepartment = lazy(
  () => import('~/pages/company/Setting/Department/Create'),
);

const UpdateDepartment = lazy(
  () => import('~/pages/company/Setting/Department/Update'),
);

const ChangeEmail = lazy(() => import('~/pages/company/Auth/ChangeEmail'));
const ChangeEmailSuccess = lazy(
  () => import('~/pages/company/Auth/ChangeEmailSuccess'),
);
const ChangePassword = lazy(
  () => import('~/pages/company/Auth/ChangePassword'),
);

const CreateEvent = lazy(() => import('~/pages/shared/Event/Create'));
const EditEvent = lazy(() => import('~/pages/shared/Event/Edit'));
const ListEvent = lazy(() => import('~/pages/shared/Event/List'));
const DetailEvent = lazy(() => import('~/pages/shared/Event/Detail'));
const ListTarget = lazy(() => import('~/pages/company/Target/List'));
const CreateTarget = lazy(() => import('~/pages/company/Target/Create'));
const UpdateTarget = lazy(() => import('~/pages/company/Target/Update'));

const ListUserJoinedEvent = lazy(
  () => import('~/pages/shared/Event/ListUserJoined'),
);
interface Props {
  children: React.ReactNode;
}

const PublicRoute = ({ children }: Props): React.ReactElement => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth?.isAuthenticated,
  );

  useEffect(() => {
    if (isAuthenticated) {
      window.location.href = '/';
    }
  }, []);

  return !isAuthenticated ? <div>{children}</div> : <LoadingView loading />;
};

const ProtectedRoute = ({ children }: Props): React.ReactElement => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth?.isAuthenticated,
  );
  const lastRoles: string[] = useSelector(
    (state: RootState) => state.auth?.lastRoles,
  );
  const location = useLocation();

  let url: string;

  if (lastRoles && lastRoles.length) {
    url = lastRoles.includes(USER_ROLE.SYSTEM_ADMIN)
      ? ROUTE.SYSTEM
      : ROUTE.COMPANY;
  } else {
    url = location.pathname.includes(ROUTE.SYSTEM)
      ? ROUTE.SYSTEM
      : ROUTE.COMPANY;
  }

  return isAuthenticated ? (
    <div>{children}</div>
  ) : (
    <Navigate to={`${url}/login`} />
  );
};

const RoleAccess = ({
  allowedRoles,
  children,
  disableForDummy = false,
}): React.ReactElement => {
  const user = useSelector((state: RootState) => state.auth?.userInfo);
  const userRoles = useSelector(
    (state: RootState) => state.auth?.userInfo?.userRoles || [],
  );

  if (disableForDummy && user?.companyId === DUMMY_COMPANY_ID) {
    return <NotFound />;
  }

  return allowedRoles.find((role) => userRoles.includes(role)) ? (
    <>{children}</>
  ) : (
    <NotFound />
  );
};

const systemMainRoute = PATH.system.notification.company.list;
const companyMainRoute = PATH.company.notification.company.list;

const useRouteElements = ():
  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  | any => {
  const userRoles = useSelector(
    (state: RootState) => state.auth?.userInfo?.userRoles || [],
  );
  const routeElements = useRoutes([
    {
      element: (
        <ProtectedRoute>
          <MainLayout />
        </ProtectedRoute>
      ),
      children: [
        {
          path: '/system',
          element: <Navigate to={systemMainRoute} />,
        },
        {
          path: '/company',
          element: <Navigate to={companyMainRoute} />,
        },
        {
          path: '/',
          element: userRoles.includes(USER_ROLE.SYSTEM_ADMIN) ? (
            <Navigate to={systemMainRoute} />
          ) : (
            <Navigate to={companyMainRoute} />
          ),
        },
        {
          path: PATH.company.user.create,
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.COMPANY]}>
              <CreateUser />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.member.create,
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.COMPANY]}>
              <CreateMember />
            </RoleAccess>
          ),
        },
        {
          path: PATH.system.company.create,
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <CreateCompanySystem />
            </RoleAccess>
          ),
        },
        {
          path: PATH.system.company.list,
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <ListCompanySystem />
            </RoleAccess>
          ),
        },
        {
          path: PATH.system.user.list,
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <ListUserSystem />
            </RoleAccess>
          ),
        },
        {
          path: PATH.system.questionnaire.list,
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <ListQuestionnaire />
            </RoleAccess>
          ),
        },
        {
          path: PATH.system.questionnaire.create,
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <QuestionnaireCreate />
            </RoleAccess>
          ),
        },
        {
          path: PATH.system.questionnaire.edit(),
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <QuestionnaireEdit />
            </RoleAccess>
          ),
        },
        {
          path: PATH.system.questionnaire.detail(),
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <QuestionnaireDetail />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.questionnaire.edit(),
          element: (
            <RoleAccess
              allowedRoles={[USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER]}
            >
              <QuestionnaireEdit isCompany />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.questionnaire.detail(),
          element: (
            <RoleAccess
              allowedRoles={[USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER]}
            >
              <QuestionnaireDetail isCompany />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.questionnaire.create,
          element: (
            <RoleAccess
              allowedRoles={[USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER]}
            >
              <QuestionnaireCreate isCompany />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.questionnaire.list,
          element: (
            <RoleAccess
              allowedRoles={[USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER]}
            >
              <ListQuestionnaire isCompany />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.healthCheck.list,
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.COMPANY]}>
              <HealthCheckList isCompany />
            </RoleAccess>
          ),
        },
        {
          path: PATH.system.healthCheck.list,
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <HealthCheckList />
            </RoleAccess>
          ),
        },
        {
          path: PATH.system.healthCheck.create,
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <HealthCheckCreate />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.healthCheck.create,
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.COMPANY]}>
              <HealthCheckCreate isCompany />
            </RoleAccess>
          ),
        },
        {
          path: PATH.system.healthCheck.listUserAnswer(),
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <ListUserAnswer />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.healthCheck.listUserAnswer(),
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.COMPANY]}>
              <ListUserAnswer isCompany />
            </RoleAccess>
          ),
        },
        {
          path: PATH.system.healthCheck.userAnswerDetail(),
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <UserAnswerDetail />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.healthCheck.userAnswerDetail(),
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.COMPANY]}>
              <UserAnswerDetail isCompany />
            </RoleAccess>
          ),
        },
        {
          path: PATH.system.healthCheck.edit(),
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <HealthCheckUpdate />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.healthCheck.edit(),
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.COMPANY]}>
              <HealthCheckUpdate isCompany />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.user.list,
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.COMPANY]}>
              <ListUserCompany />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.user.detail(),
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.COMPANY]}>
              <DetailUserCompany />
            </RoleAccess>
          ),
        },
        {
          path: PATH.system.user.detail(),
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <DetailUserSystem />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.user.edit(),
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.COMPANY]}>
              <UpdateUserCompany />
            </RoleAccess>
          ),
        },
        {
          path: PATH.system.user.edit(),
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <UpdateUserSystem />
            </RoleAccess>
          ),
        },
        {
          path: PATH.system.company.detail(),
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <DetailCompanySystem />
            </RoleAccess>
          ),
        },
        {
          path: PATH.system.company.edit(),
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <UpdateCompanySystem />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.setting.index,
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.COMPANY]}>
              <DetailCompanySetting />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.setting.updateCompany,
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.COMPANY]}>
              <UpdateCompanySetting />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.member.edit(),
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.COMPANY]}>
              <UpdateMember />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.setting.department.list,
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.COMPANY]}>
              <ListDepartment />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.setting.department.create,
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.COMPANY]}>
              <CreateDepartment />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.setting.department.edit(),
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.COMPANY]}>
              <UpdateDepartment />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.target.list,
          element: (
            <RoleAccess
              allowedRoles={[USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER]}
            >
              <ListTarget />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.target.create,
          element: (
            <RoleAccess
              allowedRoles={[USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER]}
            >
              <CreateTarget />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.target.edit(),
          element: (
            <RoleAccess
              allowedRoles={[USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER]}
            >
              <UpdateTarget />
            </RoleAccess>
          ),
        },
        {
          path: PATH.metType.system.list,
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <ListMetType isAdmin={true} />
            </RoleAccess>
          ),
        },
        {
          path: PATH.metType.company.list,
          element: (
            <RoleAccess
              allowedRoles={[USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER]}
            >
              <ListMetType />
            </RoleAccess>
          ),
        },
        {
          path: PATH.metType.system.create,
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <CreateMetType />
            </RoleAccess>
          ),
        },
        {
          path: PATH.metType.system.edit(),
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <UpdateMetType />
            </RoleAccess>
          ),
        },
        {
          path: PATH.system.notification.company.list,
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <ListNotification target={NotificationTarget.Company} />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.notification.company.list,
          element: (
            <RoleAccess
              allowedRoles={[USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER]}
            >
              <ListNotification target={NotificationTarget.Company} />
            </RoleAccess>
          ),
        },
        {
          path: PATH.system.notification.company.create,
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <CreateNotification target={NotificationTarget.Company} />
            </RoleAccess>
          ),
        },
        {
          path: PATH.system.notification.user.list,
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <ListNotification target={NotificationTarget.User} />
            </RoleAccess>
          ),
        },
        {
          path: PATH.system.notification.user.create,
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <CreateNotification target={NotificationTarget.User} />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.notification.user.list,
          element: (
            <RoleAccess
              allowedRoles={[USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER]}
            >
              <ListNotification target={NotificationTarget.User} />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.notification.user.create,
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.COMPANY]}>
              <CreateNotification target={NotificationTarget.User} />
            </RoleAccess>
          ),
        },
        {
          path: PATH.system.notification.company.detail(),
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <DetailNotification target={NotificationTarget.Company} />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.notification.company.detail(),
          element: (
            <RoleAccess
              allowedRoles={[USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER]}
            >
              <DetailNotification target={NotificationTarget.Company} />
            </RoleAccess>
          ),
        },
        {
          path: PATH.system.notification.user.detail(),
          element: (
            <RoleAccess
              allowedRoles={[
                USER_ROLE.SYSTEM_ADMIN,
                USER_ROLE.COMPANY,
                USER_ROLE.COMPANY_MEMBER,
              ]}
            >
              <DetailNotification target={NotificationTarget.User} />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.notification.user.detail(),
          element: (
            <RoleAccess
              allowedRoles={[USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER]}
            >
              <DetailNotification target={NotificationTarget.User} />
            </RoleAccess>
          ),
        },
        {
          path: PATH.system.notification.company.edit(),
          element: (
            <RoleAccess
              allowedRoles={[USER_ROLE.SYSTEM_ADMIN, USER_ROLE.COMPANY]}
            >
              <UpdateNotification target={NotificationTarget.Company} />
            </RoleAccess>
          ),
        },
        {
          path: PATH.system.notification.user.edit(),
          element: (
            <RoleAccess
              allowedRoles={[USER_ROLE.SYSTEM_ADMIN, USER_ROLE.COMPANY]}
            >
              <UpdateNotification target={NotificationTarget.User} />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.notification.user.edit(),
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.COMPANY]}>
              <UpdateNotification target={NotificationTarget.User} />
            </RoleAccess>
          ),
        },
        {
          path: PATH.system.service.list,
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <ListService />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.service.list,
          element: (
            <RoleAccess
              allowedRoles={[USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER]}
            >
              <ListService isCompany />
            </RoleAccess>
          ),
        },
        {
          path: PATH.system.service.create,
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <CreateService />
            </RoleAccess>
          ),
        },
        {
          path: PATH.system.service.edit(),
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <UpdateService />
            </RoleAccess>
          ),
        },
        {
          path: PATH.system.service.detail(),
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <DetailService />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.service.detail(),
          element: (
            <RoleAccess
              allowedRoles={[USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER]}
            >
              <DetailService isCompany />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.changeEmail,
          element: (
            <RoleAccess
              allowedRoles={[USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER]}
            >
              <ChangeEmail />
            </RoleAccess>
          ),
        },
        {
          path: PATH.company.changePassword,
          element: (
            <RoleAccess
              allowedRoles={[USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER]}
            >
              <ChangePassword />
            </RoleAccess>
          ),
        },
        {
          path: PATH.event.company.create,
          element: (
            <RoleAccess
              allowedRoles={[USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER]}
            >
              <CreateEvent />
            </RoleAccess>
          ),
        },
        {
          path: PATH.event.company.edit(),
          element: (
            <RoleAccess
              allowedRoles={[USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER]}
            >
              <EditEvent />
            </RoleAccess>
          ),
        },
        {
          path: PATH.event.company.list,
          element: (
            <RoleAccess
              allowedRoles={[USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER]}
            >
              <ListEvent />
            </RoleAccess>
          ),
        },
        {
          path: PATH.event.system.list,
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <ListEvent isAdmin />
            </RoleAccess>
          ),
        },
        {
          path: PATH.event.company.detail(),
          element: (
            <RoleAccess
              allowedRoles={[USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER]}
            >
              <DetailEvent />
            </RoleAccess>
          ),
        },
        {
          path: PATH.event.system.detail(),
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <DetailEvent isAdmin />
            </RoleAccess>
          ),
        },
        {
          path: PATH.event.company.user(),
          element: (
            <RoleAccess
              allowedRoles={[USER_ROLE.COMPANY, USER_ROLE.COMPANY_MEMBER]}
            >
              <ListUserJoinedEvent />
            </RoleAccess>
          ),
        },
        {
          path: PATH.event.system.user(),
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <ListUserJoinedEvent isAdmin />
            </RoleAccess>
          ),
        },
        {
          path: PATH.event.system.create,
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <CreateEvent />
            </RoleAccess>
          ),
        },
        {
          path: PATH.event.system.edit(),
          element: (
            <RoleAccess allowedRoles={[USER_ROLE.SYSTEM_ADMIN]}>
              <EditEvent />
            </RoleAccess>
          ),
        },
      ],
    },
    {
      element: (
        <PublicRoute>
          <MainLayout showSider={false} />
        </PublicRoute>
      ),
      children: [
        {
          path: PATH.system.login,
          element: <LoginSystem />,
        },
        {
          path: PATH.company.login,
          element: <LoginCompany />,
        },
      ],
    },
    {
      element: <MainLayout showSider={false} />,
      children: [
        {
          path: PATH.setPassword,
          element: <SetPassword />,
        },
        {
          path: PATH.forgotMail,
          element: <ForgotMail />,
        },
        {
          path: PATH.privacyPolicy,
          element: <PrivacyPolicy />,
        },
        {
          path: PATH.terms,
          element: <Terms />,
        },
        {
          path: PATH.download,
          element: <DownloadApp />,
        },
        {
          path: PATH.forgotPasswordStep1,
          element: <ForgotPasswordStep1 />,
        },
        {
          path: PATH.forgotPasswordStep2,
          element: <ForgotPasswordStep2 />,
        },
        {
          path: PATH.forgotPasswordStep3,
          element: <ForgotPasswordStep3 />,
        },
        {
          path: PATH.forgotPasswordStep4,
          element: <ForgotPasswordStep4 />,
        },
        {
          path: PATH.company.changeEmailSuccess,
          element: <ChangeEmailSuccess />,
        },
        {
          path: '*',
          element: <NotFound />,
        },
      ],
    },
  ]);

  return routeElements;
};

export default useRouteElements;
