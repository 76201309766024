/* eslint-disable consistent-return */
/* eslint-disable no-console */
import { apolloClient } from '~/apollo';
import { VERIFY_CHANGE_MAIL, VERIFY_TOKEN } from '~/graphql/queries';

export const verifyTokenService = async (token: string): Promise<any> => {
  try {
    const res = await apolloClient.query({
      query: VERIFY_TOKEN,
      variables: { token },
    });
    return res.data;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

export const verifyChangeEmailService = async (token: string): Promise<any> => {
  return apolloClient.query({
    query: VERIFY_CHANGE_MAIL,
    variables: {
      token,
    },
  });
};
