/* eslint-disable no-underscore-dangle */
import { gql } from '@apollo/client';

export const VERIFY_TOKEN = gql`
  query VerifyToken($token: String!) {
    verifyToken(token: $token)
  }
`;

export const GET_USER_INFO = gql`
  query getInfoLogin {
    getUserInfo {
      id
      avatar
      birthday
      name
      email
      isPrimary
      companyId
      userRoles
      company {
        address1
        businessForm
        createdAt
        email
        id
        name
        personInCharge
        statusModifiedAt
        status
        type
      }
    }
  }
`;

export const LIST_SERVICE = (isCountUser: boolean): any => gql`
  query ListService($where: ServiceWhereInput, $pagination: PaginationInput, $orderBy: ServiceOrderByInput, $orderByItem: ServiceItemOrderByInput) {
    listService(where: $where, pagination: $pagination, orderBy: $orderBy, orderByItem: $orderByItem) {
      data {
        id
        ${isCountUser ? 'numberUsers' : ''}
        limit
        name
        order
        itemsName
        isDefault
        status
        description
        key
        serviceItems {
          id
          serviceId
          name
          unit
        }
      }
      pagination {
        currentPage
        perPage
        total
      }
    }
  }
`;

export const LIST_PREFECTURE = gql`
  query ListPrefecture {
    listPrefecture {
      data {
        prefecture
        id
      }
    }
  }
`;

export const LIST_DEPARTMENT = gql`
  query ListCompanyDepartment($where: CompanyDepartmentWhereInput) {
    listCompanyDepartment(where: $where) {
      data {
        name
        id
        companyId
        createdAt
        updatedAt
      }
    }
  }
`;

export const LIST_COMPANY = gql`
  query ListCompany(
    $pagination: PaginationInput
    $where: CompanyWhereInput
    $orderBy: CompanyOrderByInput
  ) {
    listCompany(pagination: $pagination, where: $where, orderBy: $orderBy) {
      data {
        address1
        address1Kana
        address2
        address2Kana
        adminEmail
        businessForm
        email
        createdAt
        name
        personInCharge
        status
        id
      }
      pagination {
        currentPage
        perPage
        total
      }
    }
  }
`;

export const LIST_USER = gql`
  query ListUser(
    $pagination: PaginationInput
    $where: UserWhereInput
    $orderBy: UserOrderByInput
  ) {
    listUser(pagination: $pagination, where: $where, orderBy: $orderBy) {
      data {
        id
        createdAt
        userServices {
          serviceId
        }
        company {
          id
          name
        }
        birthday
        email
        gender
        name
        status
        userRoles
        type
        subId
      }
      pagination {
        currentPage
        perPage
        total
      }
    }
  }
`;

export const EXPORT_CSV_END_USER = gql`
  query ExportCSVEndUser($data: ExportCSVEndUserInput!) {
    exportCSVEndUser(data: $data) {
      fileUrl
      fileName
    }
  }
`;

export const GET_USER = gql`
  query GetUser($userId: String!) {
    getUser(userId: $userId) {
      gender
      height
      id
      name
      weight
      lastSignInAt
      email
      status
      registeredAt
      statusModifiedAt
      prefectureId
      occupation
      birthday
      departmentId
      userRoles
      companyId
      type
      subId
      department {
        name
        id
      }
      prefecture {
        prefecture
      }
      userServices {
        name
        serviceId
        order
        key
        serviceItems {
          id
          name
          unit
        }
      }
    }
  }
`;

export const GET_COMPANY = gql`
  query GetCompany($companyId: String) {
    detailCompany(id: $companyId) {
      address1
      address1Kana
      address2
      address2Kana
      adminEmail
      businessForm
      city
      cityKana
      createdAt
      email
      id
      name
      type
      personInCharge
      postalCode
      prefectureId
      status
      statusModifiedAt
      updatedAt
      userId
      companyServices {
        companyId
        id
        serviceId
        userId
      }
    }
  }
`;

export const LIST_COMPANY_MEMBER = gql`
  query ListCompanyMember(
    $where: UserWhereInput
    $pagination: PaginationInput
  ) {
    listCompanyMember(where: $where, pagination: $pagination) {
      data {
        name
        email
        isPrimary
        userRoles
        id
      }
      pagination {
        total
        perPage
        currentPage
      }
    }
  }
`;

export const LIST_COMPANY_DEPARTMENT = gql`
  query ListCompanyDepartment(
    $where: CompanyDepartmentWhereInput
    $pagination: PaginationInput
    $orderBy: CompanyDepartmentOrderByInput
  ) {
    listCompanyDepartment(
      where: $where
      pagination: $pagination
      orderBy: $orderBy
    ) {
      data {
        name
        id
        companyId
        createdAt
        updatedAt
        _count {
          users
        }
      }
      pagination {
        currentPage
        perPage
        total
      }
    }
  }
`;
export const GRAPH_EXERCISE_MANAGEMENT = gql`
  query GraphExerciseManagement(
    $from: AWSDateTime!
    $to: AWSDateTime!
    $mode: ChunkUnitOfTime!
    $userId: String
  ) {
    graphExerciseManagement(
      from: $from
      to: $to
      mode: $mode
      userId: $userId
    ) {
      data {
        calorie
        createdAt
        id
        metType {
          createdAt
          description
          id
          name
          speed
          value
        }
        metTypeId
        recordedAt
        time
        updatedAt
        userId
        weight
      }
      value {
        calorie
        createdAt
        id
        metType {
          createdAt
          description
          id
          name
          speed
          value
        }
        metTypeId
        recordedAt
        time
        updatedAt
        userId
        weight
      }
    }
  }
`;
export const LIST_MET_TYPE = gql`
  query ListMetType(
    $orderBy: MetTypeOrderByInput
    $pagination: PaginationInput
    $where: MetTypeWhereInput
  ) {
    listMetType(orderBy: $orderBy, pagination: $pagination, where: $where) {
      data {
        description
        name
        id
        speed
        value
        createdAt
      }
    }
  }
`;
export const HISTORY_EXERCISE_MANAGEMENT = gql`
  query HistoryExerciseManagement(
    $pagination: PaginationInput
    $userId: String!
  ) {
    historyExerciseManagement(
      pagination: $pagination
      userId: $userId
      orderBy: { recordedAt: desc }
    ) {
      data {
        calorie
        createdAt
        updatedAt
        id
        metType {
          description
          id
          name
          speed
          value
        }
        metTypeId
        recordedAt
        time
        userId
        weight
      }
      pagination {
        currentPage
        perPage
        total
      }
    }
  }
`;

export const GET_COMPANY_DEPARTMENT = gql`
  query DetailDepartment($id: String!) {
    detailDepartment(id: $id) {
      companyId
      id
      name
    }
  }
`;

export const LIST_NOTIFICATION = gql`
  query ListNotification(
    $target: NotificationTarget!
    $pagination: PaginationInput
    $where: NotificationWhereInput
  ) {
    listNotification(target: $target, pagination: $pagination, where: $where) {
      data {
        id
        isPublishNow
        publishTime
        type
        title
        isMajor
        personInCharge
      }
      pagination {
        currentPage
        perPage
        total
      }
    }
  }
`;
export const HISTORY_AGE_GLYCATION = gql`
  query HistoryAgeGlycation($pagination: PaginationInput, $userId: String!) {
    historyAgeGlycation(
      pagination: $pagination
      userId: $userId
      orderBy: { recordedAt: desc }
    ) {
      data {
        id
        recordedAt
        userId
        value
        createdAt
      }
      pagination {
        currentPage
        perPage
        total
      }
    }
  }
`;

export const HISTORY_BLOOD_GLUCOSE = gql`
  query HistoryBloodGlucose($pagination: PaginationInput, $userId: String!) {
    historyBloodGlucose(
      pagination: $pagination
      userId: $userId
      orderBy: { recordedAt: desc }
    ) {
      data {
        id
        recordedAt
        userId
        value
        createdAt
      }
      pagination {
        currentPage
        perPage
        total
      }
    }
  }
`;

export const HISTORY_PULSWAVE = gql`
  query HistoryPulseWave($pagination: PaginationInput, $userId: String!) {
    historyPulseWave(
      pagination: $pagination
      userId: $userId
      orderBy: { recordedAt: desc }
    ) {
      data {
        bulkModulus
        closePressure
        expansionRate
        id
        openPressure
        recordedAt
        userGender
        userId
        vascularAge
        createdAt
      }
      pagination {
        currentPage
        perPage
        total
      }
    }
  }
`;

export const HISTORY_GRIP_STRENGTH = gql`
  query HistoryGripStrength($pagination: PaginationInput, $userId: String!) {
    historyGripStrength(
      pagination: $pagination
      userId: $userId
      orderBy: { recordedAt: desc }
    ) {
      data {
        id
        recordedAt
        userId
        value
        createdAt
      }
      pagination {
        currentPage
        perPage
        total
      }
    }
  }
`;

export const HISTORY_BLOOD_PRESSURE = gql`
  query HistoryBloodPressure($pagination: PaginationInput, $userId: String!) {
    historyBloodPressure(
      pagination: $pagination
      userId: $userId
      orderBy: { recordedAt: desc }
    ) {
      data {
        diastolic
        id
        recordedAt
        systolic
        userId
        createdAt
      }
      pagination {
        currentPage
        perPage
        total
      }
    }
  }
`;

export const HISTORY_CAPILLARY_TYPE = gql`
  query HistoryCapillaryType($pagination: PaginationInput, $userId: String!) {
    historyCapillaryType(
      pagination: $pagination
      userId: $userId
      orderBy: { recordedAt: desc }
    ) {
      data {
        id
        recordedAt
        userId
        value
        createdAt
      }
      pagination {
        currentPage
        perPage
        total
      }
    }
  }
`;

export const HISTORY_CUSTOM_SERVICE = gql`
  query HistoryUserServicesRecord(
    $data: HistoryUserServicesInput
    $pagination: PaginationInput
  ) {
    historyUserServicesRecord(data: $data, pagination: $pagination) {
      data {
        id
        userId
        serviceId
        userServiceItemDetails {
          serviceItem {
            name
            id
            key
            target
            unit
            serviceId
          }
          serviceItemId
          value
        }
        recordedAt
        createdAt
      }
      pagination {
        currentPage
        perPage
        total
      }
    }
  }
`;

export const FILTER_LIST_USER = gql`
  query ListUser($where: UserWhereInput) {
    listUser(where: $where) {
      data {
        email
        id
        name
        type
        subId
      }
    }
  }
`;
export const GRAPH_BLOOD_GLUCOSE = gql`
  query GraphBloodGlucose(
    $from: AWSDateTime!
    $to: AWSDateTime!
    $mode: ChunkUnitOfTime!
    $userId: String
  ) {
    graphBloodGlucose(from: $from, to: $to, mode: $mode, userId: $userId) {
      value
      data {
        value
        userId
        recordedAt
        id
        createdAt
      }
    }
  }
`;

export const FILTER_LIST_COMPANY = gql`
  query ListCompany($where: CompanyWhereInput) {
    listCompany(where: $where) {
      data {
        id
        name
        email
      }
    }
  }
`;
export const GRAPH_PULSEWAVE = gql`
  query GraphPulseWave(
    $from: AWSDateTime!
    $to: AWSDateTime!
    $mode: ChunkUnitOfTime!
    $userId: String
  ) {
    graphPulseWave(from: $from, to: $to, mode: $mode, userId: $userId) {
      data {
        bulkModulus
        closePressure
        createdAt
        expansionRate
        id
        openPressure
        recordedAt
        userGender
        userId
        vascularAge
      }
      value {
        bulkModulus
        closePressure
        createdAt
        expansionRate
        id
        openPressure
        recordedAt
        userGender
        userId
        vascularAge
      }
    }
  }
`;

export const GRAPH_CUSTOM_SERVICE = gql`
  query GraphUserServicesRecord($data: GraphUserServicesInput) {
    graphUserServicesRecord(data: $data) {
      value
      data {
        value
        recordedAt
      }
    }
  }
`;

export const GET_UPLOAD_URL = gql`
  query GetUploadUrl($fileName: String!, $contentType: String!) {
    getUploadUrl(fileName: $fileName, contentType: $contentType) {
      url
      fileName
    }
  }
`;
export const GRAPH_GRIP_STRENGTH = gql`
  query GraphGripStrength(
    $from: AWSDateTime!
    $to: AWSDateTime!
    $mode: ChunkUnitOfTime!
    $userId: String
  ) {
    graphGripStrength(from: $from, to: $to, mode: $mode, userId: $userId) {
      data {
        createdAt
        id
        recordedAt
        userId
        value
      }
      value
    }
  }
`;

export const DETAIL_NOTIFICATION = gql`
  query DetailNotification($notificationId: String!) {
    detailNotification(notificationId: $notificationId) {
      id
      isPublishNow
      publishTime
      type
      companies {
        id
        name
      }
      users {
        id
        name
      }
      title
      content
      isMajor
      personInCharge
      companyId
      userId
      createdAt
      largeImage
      smallImage
    }
  }
`;
export const GRAPH_BLOOD_PRESSURE = gql`
  query GraphBloodPressure(
    $from: AWSDateTime!
    $to: AWSDateTime!
    $mode: ChunkUnitOfTime!
    $userId: String
  ) {
    graphBloodPressure(from: $from, to: $to, mode: $mode, userId: $userId) {
      data {
        createdAt
        diastolic
        id
        recordedAt
        systolic
        userId
      }
      diastolic
      systolic
    }
  }
`;

export const GRAPH_AGE_GLYCATION = gql`
  query GraphAgeGlycation(
    $from: AWSDateTime!
    $to: AWSDateTime!
    $mode: ChunkUnitOfTime!
    $userId: String
  ) {
    graphAgeGlycation(from: $from, to: $to, mode: $mode, userId: $userId) {
      data {
        createdAt
        id
        recordedAt
        userId
        value
      }
      value
    }
  }
`;

export const DETAIL_SERVICE = gql`
  query DetailService($id: String!) {
    detailService(id: $id) {
      id
      name
      description
      serviceItems {
        id
        serviceId
        name
        unit
      }
      status
      isDefault
      order
      limit
      companyId
      contractStartAt
      contractEndAt
      _count {
        companyServices
      }
    }
  }
`;
export const GRAPH_CAPILLARY_TYPE = gql`
  query GraphCapillaryType(
    $from: AWSDateTime!
    $to: AWSDateTime!
    $mode: ChunkUnitOfTime!
    $userId: String
  ) {
    graphCapillaryType(from: $from, to: $to, mode: $mode, userId: $userId) {
      data {
        createdAt
        id
        recordedAt
        userId
        value
      }
      value
    }
  }
`;

export const VERIFY_CHANGE_MAIL = gql`
  query VerifyChangeEmail($token: String!) {
    verifyChangeEmail(token: $token) {
      dynamicLink
    }
  }
`;

export const FILTER_LIST_COMPANY_DEPARTMENT = gql`
  query ListCompanyDepartment($where: CompanyDepartmentWhereInput) {
    listCompanyDepartment(where: $where) {
      data {
        name
        id
        companyId
        createdAt
        updatedAt
        _count {
          users
        }
      }
    }
  }
`;

export const LIST_EVENT = gql`
  query ListEvents(
    $where: EventWhereInput
    $orderBy: EventOrderByInput
    $cursor: EventCursorInput
    $pagination: PaginationInput
    $isHistory: Boolean
  ) {
    listEvents(
      where: $where
      orderBy: $orderBy
      cursor: $cursor
      pagination: $pagination
      isHistory: $isHistory
    ) {
      events {
        id
        name
        description
        startDate
        endDate
        type
        format
        evaluation
        targetValue
        bannerImageUrl
        mainMotionImageUrl
        subMotionImageUrl
        createdAt
        updatedAt
        totalUsers
        companyId
        companyName
      }
      pagination {
        currentPage
        perPage
        total
      }
    }
  }
`;

export const DETAIL_EVENT = gql`
  query DetailEvent($eventId: String!) {
    detailEvent(eventId: $eventId) {
      data {
        id
        name
        description
        startDate
        endDate
        type
        format
        evaluation
        targetValue
        bannerImageUrl
        mainMotionImageUrl
        subMotionImageUrl
        createdAt
        updatedAt
        totalUsers
        isUserJoined
        eventUsers {
          id
          eventId
          userId
        }
        eventCondition {
          id
          eventId
          enableUserHistory
          enableUserGender
          enableUserAge
          enableUserDepartment
          userHistoryFrom
          userHistoryTo
          userGender
          userAgeFrom
          userAgeTo
          department {
            id
            name
          }
        }
      }
    }
  }
`;

export const FILTER_LIST_SERVICE_TARGET = gql`
  query ListServiceTarget(
    $where: ServiceTargetWhereInput
    $orderBy: ServiceTargetOrderByInput
    $cursor: ServiceTargetCursorInput
    $pagination: PaginationInput
  ) {
    listServiceTarget(
      where: $where
      orderBy: $orderBy
      cursor: $cursor
      pagination: $pagination
    ) {
      data {
        id
        name
        companyId
        serviceId
        createdAt
        updatedAt
        service {
          id
          name
          order
        }
      }
      pagination {
        currentPage
        perPage
        total
      }
    }
  }
`;
export const LIST_QUESTIONNAIRE = gql`
  query ListQuestionnaire(
    $where: QuestionnaireWhereInput
    $pagination: PaginationInput
    $orderBy: QuestionnaireOrderByInput
  ) {
    listQuestionnaire(
      where: $where
      pagination: $pagination
      orderBy: $orderBy
    ) {
      data {
        id
        title
        description
        createdAt
        startAt
        finishedAt
        status
        createdBy
        numOfUser
        category
      }
      pagination {
        currentPage
        perPage
        total
      }
    }
  }
`;

export const DETAIL_SERVICE_TARGET = gql`
  query DetailServiceTarget($id: String!) {
    detailServiceTarget(id: $id) {
      id
      name
      companyId
      serviceId
      serviceItemTargets {
        id
        target
        serviceItemId
        serviceTargetId
      }
      service {
        id
        name
        serviceItems {
          id
          name
          unit
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const LIST_SERVICE_ITEM = gql`
  query ListServiceItem($where: ServiceItemWhereInput) {
    listServiceItem(where: $where) {
      id
      serviceId
      name
      unit
      target
      key
    }
  }
`;

export const LIST_USER_JOINED_EVENT = gql`
  query ListUserJoinedEvent(
    $eventId: String!
    $where: UsersJoinedEventWhereInput
    $orderBy: UsersJoinedEventOrderByInput
    $pagination: PaginationInput
  ) {
    listUserJoinedEvent(
      eventId: $eventId
      where: $where
      pagination: $pagination
      orderBy: $orderBy
    ) {
      type
      avg
      companyId
      companyName
      female
      male
      other
      participants
      sum
      users {
        birthday
        email
        gender
        joinDate
        name
        rank
        userId
        type
        subId
      }
      pagination {
        currentPage
        perPage
        total
      }
    }
  }
`;
export const DETAIL_QUESTIONNAIRE = gql`
  query Query($detailQuestionnaireId: String!) {
    detailQuestionnaire(id: $detailQuestionnaireId) {
      id
      title
      description
      questions {
        id
        content
        isRequired
        type
        explain
        isShowExplain
        highlyRatingLabel
        lowRatingLabel
        answers {
          id
          content
          order
          numOfUser
        }
        groupAnswers {
          id
          title
          order
          answers {
            id
            content
            order
            numOfUser
          }
        }
        numOfUser
      }
      createdAt
      startAt
      finishedAt
      status
      createdBy
      numOfUser
      category
    }
  }
`;

export const EXPORT_CSV_QUESTIONNAIRE = gql`
  query ExportQuestionnaire($data: ExportQuestionnaireInput!) {
    exportQuestionnaire(data: $data) {
      fileUrl
    }
  }
`;

export const HISTORY_HEART_RATE = gql`
  query MyQuery(
    $from: AWSDateTime!
    $to: AWSDateTime!
    $userId: String
    $mode: ChunkUnitOfTime
  ) {
    historyHeartRate(from: $from, to: $to, userId: $userId, mode: $mode) {
      data {
        minValue
        maxValue
        avgValue
        recordedAt {
          from
          to
        }
      }
    }
  }
`;

export const GRAPH_HEART_RATE = gql`
  query MyQuery(
    $from: AWSDateTime!
    $to: AWSDateTime!
    $mode: ChunkUnitOfTime!
    $userId: String
  ) {
    graphHeartRate(from: $from, to: $to, mode: $mode, userId: $userId) {
      data {
        avgValue
        maxValue
        minValue
        recordedAt {
          from
          to
        }
      }
      maxValue
      minValue
    }
  }
`;

export const GET_GRAPH_STEP = gql`
  query MyQuery(
    $from: AWSDateTime!
    $to: AWSDateTime!
    $mode: ChunkUnitOfTime!
    $userId: String
  ) {
    graphStep(data: { from: $from, mode: $mode, to: $to, userId: $userId }) {
      data {
        recordedAt {
          from
          to
        }
        steps
        userId
      }
      avgStep
    }
  }
`;

export const GET_HISTORY_STEP = gql`
  query MyQuery(
    $from: AWSDateTime!
    $to: AWSDateTime!
    $userId: String
    $mode: ChunkUnitOfTime
  ) {
    historyStep(from: $from, to: $to, userId: $userId, mode: $mode) {
      data {
        recordedAt {
          to
          from
        }
        userId
        steps
      }
    }
  }
`;

export const GET_HISTORY_EXERCISE = gql`
  query HistoryExercise(
    $from: AWSDateTime!
    $to: AWSDateTime!
    $userId: String
    $mode: ChunkUnitOfTime
  ) {
    historyExercise(from: $from, to: $to, userId: $userId, mode: $mode) {
      data {
        value
        startTime
        endTime
      }
    }
  }
`;

export const GET_GRAPH_EXERCISE = gql`
  query GraphExercise(
    $from: AWSDateTime!
    $to: AWSDateTime!
    $mode: ChunkUnitOfTime!
    $userId: String
  ) {
    graphExercise(from: $from, to: $to, mode: $mode, userId: $userId) {
      data {
        value
        recordedAt
      }
      value
    }
  }
`;

export const GET_GRAPH_CALORIE = gql`
  query MyQuery(
    $from: AWSDateTime!
    $to: AWSDateTime!
    $mode: ChunkUnitOfTime!
    $userId: String
  ) {
    graphCalorie(from: $from, to: $to, mode: $mode, userId: $userId) {
      data {
        recordedAt {
          from
          to
        }
        value
      }
      value
    }
  }
`;

export const GET_HISTORY_CALORIE = gql`
  query MyQuery(
    $from: AWSDateTime!
    $to: AWSDateTime!
    $mode: ChunkUnitOfTime
    $userId: String
  ) {
    historyCalorie(from: $from, to: $to, mode: $mode, userId: $userId) {
      data {
        value
        recordedAt {
          from
          to
        }
      }
    }
  }
`;

export const GET_GRAPH_STAND = gql`
  query GraphStand(
    $from: AWSDateTime!
    $to: AWSDateTime!
    $mode: ChunkUnitOfTime!
    $userId: String
  ) {
    graphStand(from: $from, to: $to, mode: $mode, userId: $userId) {
      data {
        value
        recordedAt {
          from
          to
        }
      }
      value
    }
  }
`;

export const GET_HISTORY_STAND = gql`
  query HistoryStand(
    $from: AWSDateTime!
    $to: AWSDateTime!
    $mode: ChunkUnitOfTime
    $userId: String
  ) {
    historyStand(from: $from, to: $to, mode: $mode, userId: $userId) {
      data {
        value
        recordedAt {
          from
          to
        }
      }
    }
  }
`;

export const GET_HISTORY_FATIGUE = gql`
  query HistoryFatigue(
    $from: AWSDateTime
    $to: AWSDateTime
    $pagination: PaginationInput
    $cursor: FatigueCursorInput
    $userId: String
  ) {
    historyFatigue(
      from: $from
      to: $to
      pagination: $pagination
      cursor: $cursor
      userId: $userId
    ) {
      data {
        id
        value
        userId
        recordedAt
        createdAt
      }
      pagination {
        currentPage
        perPage
        total
      }
    }
  }
`;

export const GET_GRAPH_FATIGUE = gql`
  query GraphFatigue(
    $from: AWSDateTime!
    $to: AWSDateTime!
    $mode: ChunkUnitOfTime!
    $userId: String
  ) {
    graphFatigue(from: $from, to: $to, mode: $mode, userId: $userId) {
      data {
        value
        recordedAt {
          from
          to
        }
      }
      value
    }
  }
`;

export const GET_HISTORY_WALKING = gql`
  query MyQuery(
    $from: AWSDateTime!
    $to: AWSDateTime!
    $pagination: PaginationInput
    $cursor: WalkingCursorInput
    $userId: String
  ) {
    historyWalking(
      from: $from
      to: $to
      pagination: $pagination
      cursor: $cursor
      userId: $userId
    ) {
      data {
        id
        userId
        distance
        startTime
        endTime
      }
      totalDistance
      totalTime
      pagination {
        currentPage
        perPage
        total
      }
    }
  }
`;
export const GET_HISTORY_RUNNING = gql`
  query MyQuery(
    $from: AWSDateTime!
    $to: AWSDateTime!
    $pagination: PaginationInput
    $cursor: RunningCursorInput
    $userId: String
  ) {
    historyRunning(
      from: $from
      to: $to
      pagination: $pagination
      cursor: $cursor
      userId: $userId
    ) {
      data {
        id
        distance
        startTime
        endTime
      }
      totalDistance
      totalTime
      pagination {
        currentPage
        perPage
        total
      }
    }
  }
`;

export const GET_GRAPH_SLEEP = gql`
  query Query($from: AWSDateTime!, $to: AWSDateTime!, $userId: String) {
    graphSleep(from: $from, to: $to, userId: $userId) {
      rateTotalSleepMode {
        totalTime
        sleepMode
        rate {
          reference {
            from
            to
          }
          status
        }
        percentage
      }
      avgSleepScore
      avgTime
      data {
        inSleepTime
        outSleepTime
        sleepRanges {
          startTime
          stopTime
          sleepMode
        }
        totalBySleepMode {
          totalTime
          sleepMode
        }
        totalPerDay
        sleepScore
      }
      totalTime
    }
  }
`;
export const GET_HISTORY_SLEEP = gql`
  query MyQuery($from: AWSDateTime!, $to: AWSDateTime!, $userId: String) {
    historySleep(from: $from, to: $to, userId: $userId) {
      data {
        recordedAt {
          to
          from
        }
        value
      }
    }
  }
`;
