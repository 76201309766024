import { Layout } from 'antd';
import classNames from 'classnames/bind';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { RootState } from '~/store/store';

import Header from '../Header';
import Sider from '../Sider';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

interface IProps {
  showSider?: boolean;
}

const MainLayout = ({ showSider = true }: IProps): React.ReactElement => {
  const isAuthenticated = useSelector(
    (state: RootState): any => state.auth.isAuthenticated,
  );
  const scrollbarsRef = React.useRef<Scrollbars>(null);

  React.useEffect(() => {
    scrollbarsRef.current?.scrollToTop?.();
  }, [window.location.href]);

  return (
    <Layout className={cx('layout')}>
      <Header isAuthenticated={isAuthenticated} />
      {isAuthenticated && showSider && <Sider />}
      <Scrollbars
        style={{ width: '100%', height: ' calc(100vh - 64px)' }}
        autoHide
        ref={scrollbarsRef}
      >
        <Layout
          style={{
            padding: isAuthenticated && showSider ? 0 : undefined,
          }}
          className={cx('wrapper')}
        >
          <Outlet />
        </Layout>
      </Scrollbars>
    </Layout>
  );
};

export default MainLayout;
