import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  CreateQuestionnaireInput,
  DeleteQuestionnaireInput,
  PaginationInput,
  PaginationPageInfo,
  QuestionnaireOrderByInput,
  QuestionnaireResponse,
  QuestionnaireWhereInput,
  UpdateQuestionnaireInput,
} from '~/models/common';
import { thunkAction } from '~/utils/utils';
import {
  createQuestionnaireService,
  deleteQuestionnaireService,
  fetchDetailQuestionnaireService,
  listQuestionnaireService,
  updateQuestionnaireService,
} from '~/services/questionnaire';

interface IQuestionnaireState {
  listQuestionnaire: {
    data: QuestionnaireResponse[];
    pagination: PaginationPageInfo;
  };
  detailQuestionnaire?: QuestionnaireResponse;
  error: any;
  loading: boolean;
  created: Boolean;
  updated: Boolean;
  deleted: Boolean;
}

const initialState: IQuestionnaireState = {
  listQuestionnaire: {
    data: [],
    pagination: {} as PaginationPageInfo,
  },
  updated: false,
  created: false,
  deleted: false,
  error: {},
  loading: false,
};

export const postCreateQuestionnaire = createAsyncThunk(
  'questionnaire/create',
  thunkAction(async (data: CreateQuestionnaireInput) => {
    return createQuestionnaireService(data);
  }),
);
export const postUpdateQuestionnaire = createAsyncThunk(
  'questionnaire/update',
  thunkAction(async (data: UpdateQuestionnaireInput) => {
    return updateQuestionnaireService(data);
  }),
);
export const fetchListQuestionnaire = createAsyncThunk(
  'questionnaire/listQuestionnaire',
  thunkAction(
    async (payload: {
      pagination?: PaginationInput;
      where?: QuestionnaireWhereInput;
      orderBy?: QuestionnaireOrderByInput;
    }) => {
      return listQuestionnaireService(
        payload.where,
        payload.pagination,
        payload.orderBy,
      );
    },
  ),
);

export const fetchDetailQuestionnaire = createAsyncThunk(
  'healthCheck/detailQuestionnaire',
  thunkAction(async (id: string) => {
    return fetchDetailQuestionnaireService(id);
  }),
);

export const postDeleteQuestionnaire = createAsyncThunk(
  'healthCheck/postDeleteQuestionnaire',
  thunkAction(async (payload: DeleteQuestionnaireInput) => {
    return deleteQuestionnaireService(payload);
  }),
);

const QuestionnaireSlice = createSlice({
  initialState,
  name: 'questionnaire',
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postCreateQuestionnaire.fulfilled, (state, action) => ({
      ...state,
      error: {},
      created: action.payload.data,
      loading: false,
    }));
    builder.addCase(fetchListQuestionnaire.fulfilled, (state, action) => ({
      ...state,
      listQuestionnaire: {
        data: [...action.payload.data],
        pagination: action.payload.pagination,
      },
      error: {},
      loading: false,
    }));
    builder.addCase(fetchDetailQuestionnaire.fulfilled, (state, action) => ({
      ...state,
      error: {},
      detailQuestionnaire: action.payload,
      loading: false,
    }));
    builder.addCase(postUpdateQuestionnaire.fulfilled, (state, action) => ({
      ...state,
      error: {},
      loading: false,
      updated: action.payload.data,
    }));
    builder.addCase(postDeleteQuestionnaire.fulfilled, (state) => ({
      ...state,
      error: {},
      loading: false,
    }));
    builder.addMatcher(
      isAnyOf(
        postCreateQuestionnaire.pending,
        fetchListQuestionnaire.pending,
        fetchDetailQuestionnaire.pending,
        postUpdateQuestionnaire.pending,
        postDeleteQuestionnaire.pending,
      ),
      (state) => ({
        ...state,
        loading: true,
      }),
    );
    builder.addMatcher(
      isAnyOf(
        postCreateQuestionnaire.rejected,
        fetchListQuestionnaire.rejected,
        fetchDetailQuestionnaire.rejected,
        postUpdateQuestionnaire.rejected,
        postDeleteQuestionnaire.rejected,
      ),
      (state, action) => ({
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      }),
    );
  },
});
export default QuestionnaireSlice.reducer;
