import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  MetTypeInput,
  MetTypeRecord,
  MutationDeleteMetTypeArgs,
  MutationUpdateMetTypeArgs,
  QueryListMetTypeArgs,
  PaginationPageInfo,
} from '~/models/common';
import {
  createMetType,
  updateMetType,
  getMetTypeService,
  listMetTypeService,
  deleteMetType,
} from '~/services/metTypeService';
import { thunkAction } from '~/utils/utils';

interface IState {
  listMetType: {
    data: MetTypeRecord[];
    pagination: PaginationPageInfo;
  };
  detailMetType: MetTypeRecord;
  error: object;
  loading: boolean;
}

const initialState: IState = {
  listMetType: {
    data: [],
    pagination: {},
  } as any,
  detailMetType: {} as MetTypeRecord,
  error: {},
  loading: false,
};

export const fetchListMetType = createAsyncThunk(
  'metType/list',
  thunkAction(async (payload: QueryListMetTypeArgs) => {
    const res = await listMetTypeService(payload);
    return res;
  }),
);

export const fetchDetailMetType = createAsyncThunk(
  'metType/detail',
  thunkAction(async (payload: string) => {
    const res = await getMetTypeService(payload);
    return res?.data?.[0];
  }),
);

export const postCreateMetType = createAsyncThunk(
  'metType/create',
  thunkAction((payload: MetTypeInput) => {
    return createMetType(payload);
  }),
);

export const postUpdateMetType = createAsyncThunk(
  'metType/update',
  thunkAction((payload: MutationUpdateMetTypeArgs) => {
    return updateMetType(payload);
  }),
);

export const postDeleteMetType = createAsyncThunk(
  'metType/delete',
  thunkAction((payload: MutationDeleteMetTypeArgs) => {
    return deleteMetType(payload);
  }),
);

const MetTypeSlice = createSlice<IState, any, string>({
  name: 'metType',
  initialState,
  reducers: {
    setStatus(state, action) {
      return {
        ...state,
        status: action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchListMetType.fulfilled, (state, action) => {
      return {
        ...state,
        listMetType: {
          data: [...action.payload.data],
          pagination: { ...action.payload.pagination },
        },
        error: {},
        loading: false,
      };
    });
    builder.addCase(fetchDetailMetType.fulfilled, (state, action) => {
      return {
        ...state,
        detailMetType: { ...action.payload },
        error: {},
        loading: false,
      };
    });
    builder.addMatcher(
      isAnyOf(
        postCreateMetType.fulfilled,
        postUpdateMetType.fulfilled,
        postDeleteMetType.fulfilled,
      ),
      (state) => ({
        ...state,
        error: {},
        loading: false,
      }),
    );
    builder.addMatcher(
      isAnyOf(
        fetchListMetType.pending,
        fetchDetailMetType.pending,
        postCreateMetType.pending,
        postUpdateMetType.pending,
        postDeleteMetType.pending,
      ),
      (state) => ({
        ...state,
        loading: true,
      }),
    );
    builder.addMatcher(
      isAnyOf(
        fetchListMetType.rejected,
        fetchDetailMetType.rejected,
        postCreateMetType.rejected,
        postUpdateMetType.rejected,
        postDeleteMetType.rejected,
      ),
      (state, action) => ({
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      }),
    );
  },
});

export const { setStatus } = MetTypeSlice.actions;
export default MetTypeSlice.reducer;
