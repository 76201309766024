import { apolloClient } from '~/apollo';
import { CREATE_SERVICE, UPDATE_SERVICE } from '~/graphql/mutations';
import { DETAIL_SERVICE } from '~/graphql/queries';
import { CreateServiceInput, UpdateServiceInput } from '~/models/common';

export const createSystemService = async (
  payload: CreateServiceInput,
): Promise<any> => {
  return apolloClient.mutate({
    mutation: CREATE_SERVICE,
    variables: { data: payload },
  });
};

export const updateSystemService = async (
  payload: UpdateServiceInput,
): Promise<any> => {
  return apolloClient.mutate({
    mutation: UPDATE_SERVICE,
    variables: { data: payload },
  });
};

export const detailSystemService = async (id: string): Promise<any> => {
  return apolloClient.query({
    query: DETAIL_SERVICE,
    variables: { id },
  });
};
