import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  ExportCsvEndUserResponse,
  PaginationInput,
  PaginationPageInfo,
  UpdateUserInput,
  UserOrderByInput,
  UserResponse,
  UserStatus,
  UserWhereInput,
} from '~/models/common';
import { IUser } from '~/models/user';
import {
  changeUserStatus,
  createUser,
  deleteManyUsers,
  deleteUser,
  getUser,
  listUser,
  resendVerifyEmail,
  updateUser,
} from '~/services/userService';
import { thunkAction } from '~/utils/utils';

const initialState = {
  listUser: {
    data: [] as UserResponse[],
    pagination: {} as PaginationPageInfo,
  },
  detailUser: {} as UserResponse,
  exportCsvEndUser: { fileUrl: '' } as ExportCsvEndUserResponse,
  error: {},
  loading: false,
};

export const postCreateUser = createAsyncThunk(
  'user/create',
  thunkAction((payload: IUser) => {
    return createUser(payload);
  }),
);

export const fetchListUser = createAsyncThunk(
  'user/list',
  thunkAction(
    async (payload: {
      pagination: PaginationInput;
      where: UserWhereInput;
      orderBy: UserOrderByInput;
    }) => {
      return listUser(payload);
    },
  ),
);

export const fetchGetUser = createAsyncThunk(
  'user/detail',
  thunkAction(async (payload: { userId: string }) => {
    const res = await getUser(payload.userId);
    return res?.data?.getUser;
  }),
);

export const resendEmail = createAsyncThunk(
  'user/resend_email',
  thunkAction(async (payload: { ids: string[] }) => {
    const res = await resendVerifyEmail(payload.ids);
    return res?.data?.resendVerifyEmail;
  }),
);

export const postUpdateUser = createAsyncThunk(
  'user/update',
  thunkAction((payload: UpdateUserInput) => {
    return updateUser(payload);
  }),
);

export const postDeleteUser = createAsyncThunk(
  'company/delete',
  thunkAction((id: string) => {
    return deleteUser(id);
  }),
);

export const postChangeUserStatus = createAsyncThunk(
  'company/changeUserStatus',
  thunkAction((payload: { id: string; status: UserStatus }) => {
    return changeUserStatus(payload.id, payload.status);
  }),
);

export const postDeleteManyUser = createAsyncThunk(
  'company/deleteMany',
  thunkAction((ids: string[]) => {
    return deleteManyUsers(ids);
  }),
);

const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setStatus(state, action) {
      return {
        ...state,
        status: action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(postCreateUser.fulfilled, (state) => ({
      ...state,
      error: {},
      loading: false,
    }));
    builder.addCase(fetchListUser.fulfilled, (state, action) => {
      return {
        ...state,
        listUser: {
          data: [...action.payload.data],
          pagination: { ...action.payload.pagination },
        },
        error: {},
        loading: false,
      };
    });
    builder.addCase(fetchGetUser.fulfilled, (state, action) => {
      return {
        ...state,
        detailUser: { ...action.payload },
        error: {},
        loading: false,
      };
    });
    builder.addCase(resendEmail.fulfilled, (state) => ({
      ...state,
      error: {},
      loading: false,
    }));
    builder.addCase(postUpdateUser.fulfilled, (state) => ({
      ...state,
      error: {},
      loading: false,
    }));
    builder.addCase(postDeleteUser.fulfilled, (state) => ({
      ...state,
      error: {},
      loading: false,
    }));
    builder.addCase(postChangeUserStatus.fulfilled, (state) => ({
      ...state,
      error: {},
      loading: false,
    }));
    builder.addCase(postDeleteManyUser.fulfilled, (state) => ({
      ...state,
      error: {},
      loading: false,
    }));
    builder.addMatcher(
      isAnyOf(
        postCreateUser.pending,
        fetchListUser.pending,
        fetchGetUser.pending,
        resendEmail.pending,
        postUpdateUser.pending,
        postDeleteUser.pending,
        postChangeUserStatus.pending,
        postDeleteManyUser.pending,
      ),
      (state) => ({
        ...state,
        loading: true,
      }),
    );
    builder.addMatcher(
      isAnyOf(
        postCreateUser.rejected,
        fetchListUser.rejected,
        fetchGetUser.rejected,
        resendEmail.rejected,
        postUpdateUser.rejected,
        postDeleteUser.rejected,
        postChangeUserStatus.rejected,
        postDeleteManyUser.rejected,
      ),
      (state, action) => ({
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      }),
    );
  },
});

export const { setStatus } = UserSlice.actions;
export default UserSlice.reducer;
