import './index.css';

import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import LoadingView from '~/components/Loading';
import useRouteElements from '~/routers';
import { getUserInfo, setLogout } from '~/store/slices/auth/login';
import { AppDispatch, RootState } from '~/store/store';
import { handleError } from './utils/utils';

const App = (): React.ReactElement<
  any,
  string | React.JSXElementConstructor<any>
> | null => {
  const globalLoading = useSelector((store: RootState) => store.common.loading);
  const auth = useSelector((store: RootState) => store.auth);
  const dispatch = useDispatch<AppDispatch>();
  const routes = useRouteElements();
  const { t } = useTranslation();

  useEffect(() => {
    try {
      if (auth.tokenInfo) {
        dispatch(getUserInfo()).then((res: any) => {
          if (res?.error) {
            handleError(res, t('user.detail.fail'));
          }
        });
      }
    } catch (error) {
      dispatch(setLogout());
    }
  }, [auth.isAuthenticated]);

  if (auth.restoreToken) return <LoadingView loading={auth.restoreToken} />;

  return (
    <Suspense fallback={<LoadingView loading />}>
      <LoadingView loading={globalLoading} />
      {routes}
    </Suspense>
  );
};

export default App;
