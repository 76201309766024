import '~/index.css';

import { ApolloProvider } from '@apollo/client';
import { ConfigProvider } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { apolloClient } from '~/apollo';
import App from '~/App';
import i18n from '~/i18n';
import reportWebVitals from '~/reportWebVitals';
import { store } from '~/store/store';
import components from '~/styles/theme/overrides/components';
import token from '~/styles/theme/overrides/token';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider store={store}>
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <ConfigProvider
            theme={{
              components,
              token,
              hashed: false,
            }}
          >
            <App />
          </ConfigProvider>
        </I18nextProvider>
      </BrowserRouter>
    </ApolloProvider>
  </Provider>,
);

reportWebVitals();
