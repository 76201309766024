import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { ServiceStatus } from '~/models/common';
import {
  listDepartment,
  listPrefecture,
  listService,
} from '~/services/commonService';
import { thunkAction } from '~/utils/utils';

const initialState = {
  listService: [],
  listPrefecture: [],
  listDepartment: [],
  listPending: [] as any[],
  loading: false,
  isOpneProfile: false,
};

export const fetchListService = createAsyncThunk(
  'common/dataListService',
  thunkAction(
    async (payload: {
      companyId?: string | undefined;
      isCountUser?: boolean;
    }) => {
      const result = await listService(payload.companyId, payload.isCountUser, {
        status: {
          equals: ServiceStatus.Public,
        },
      });

      return result?.data?.listService?.data;
    },
  ),
);

export const fetchListPrefecture = createAsyncThunk(
  'common/dataPrefecture',
  thunkAction(async () => {
    const result = await listPrefecture();

    return result?.data?.listPrefecture?.data;
  }),
);

export const fetchListDepartment = createAsyncThunk(
  'common/dataDepartment',
  thunkAction(async (companyId: string) => {
    const result = await listDepartment(companyId);

    return result?.data?.listCompanyDepartment?.data;
  }),
);

const CommonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setListService(state, action) {
      return {
        ...state,
        listService: action.payload,
      };
    },
    setLoading(state, action) {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setIsOpenProfile(state, action) {
      return {
        ...state,
        isOpneProfile: action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      isAnyOf(
        fetchListService.fulfilled,
        fetchListPrefecture.fulfilled,
        fetchListDepartment.fulfilled,
      ),
      (state, action) => {
        const listPending = state.listPending.slice(1);
        const newState = {
          ...state,
        };

        switch (action.type) {
          case fetchListService.fulfilled.type:
            newState.listService = action.payload;
            break;
          case fetchListPrefecture.fulfilled.type:
            newState.listPrefecture = action.payload;
            break;
          case fetchListDepartment.fulfilled.type:
            newState.listDepartment = action.payload;
            break;
          default:
            break;
        }

        return {
          ...newState,
          listPending,
          loading: !!listPending.length,
        };
      },
    );
    builder.addMatcher(
      isAnyOf(
        fetchListService.pending,
        fetchListPrefecture.pending,
        fetchListDepartment.pending,
      ),
      (state, action) => {
        const listPending = [...state.listPending, action.type];

        return {
          ...state,
          listPending,
          loading: true,
        };
      },
    );
    builder.addMatcher(
      isAnyOf(
        fetchListService.rejected,
        fetchListPrefecture.rejected,
        fetchListDepartment.rejected,
      ),
      (state) => {
        const listPending = state.listPending.slice(1);

        return {
          ...state,
          listPending,
          loading: false,
        };
      },
    );
  },
});

export const { setListService, setLoading, setIsOpenProfile } =
  CommonSlice.actions;
export default CommonSlice.reducer;
