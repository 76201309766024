import { apolloClient } from '~/apollo';
import { DEFAULT_PER_PAGE } from '~/constants/common';
import {
  CHANGE_EMAIL,
  CHANGE_PASSWORD,
  CREATE_COMPANY,
  CREATE_DEPARTMENT,
  CREATE_SERVICE_TARGET,
  DELETE_DEPARTMENT,
  DELETE_SERVICE_TARGET,
  DELETE_USER,
  UPDATE_COMPANY,
  UPDATE_DEPARTMENT,
  UPDATE_PROFILE,
  UPDATE_SERVICE_TARGET,
} from '~/graphql/mutations';
import {
  DETAIL_SERVICE_TARGET,
  FILTER_LIST_COMPANY,
  FILTER_LIST_SERVICE_TARGET,
  GET_COMPANY,
  GET_COMPANY_DEPARTMENT,
  LIST_COMPANY,
  LIST_COMPANY_DEPARTMENT,
} from '~/graphql/queries';
import {
  CompanyWhereInput,
  ServiceTargetInput,
  ListCompanyResponse,
  MutationCreateCompanyDepartmentArgs,
  PaginationInput,
  ServiceTargetWhereInput,
  QueryMode,
  UpdateCompanyDepartmentInput,
  UpdateCompanyInput,
  ServiceTargetCursorInput,
  ServiceTargetOrderByInput,
  CompanyOrderByInput,
} from '~/models/common';

export const createCompany = async (payload): Promise<any> => {
  return apolloClient.mutate({
    mutation: CREATE_COMPANY,
    variables: {
      data: {
        ...payload,
      },
    },
  });
};

export const listCompanyService = async ({
  pagination,
  where,
  orderBy,
}: {
  pagination: PaginationInput;
  where?: CompanyWhereInput;
  orderBy?: CompanyOrderByInput;
}): Promise<ListCompanyResponse> => {
  const res = await apolloClient.query({
    query: LIST_COMPANY,
    variables: {
      orderBy,
      pagination: {
        page: pagination?.page || 1,
        perPage: DEFAULT_PER_PAGE,
      },
      where:
        Object.keys(where!).length > 0
          ? {
              id: where?.id
                ? {
                    contains: where?.id,
                  }
                : undefined,
              name: where?.name
                ? {
                    contains: where?.name,
                    mode: QueryMode.Insensitive,
                  }
                : undefined,
              personInCharge: where?.personInCharge
                ? {
                    contains: where?.personInCharge,
                    mode: QueryMode.Insensitive,
                  }
                : undefined,
              email: where?.email
                ? {
                    contains: where?.email,
                    mode: QueryMode.Insensitive,
                  }
                : undefined,
            }
          : undefined,
    },
  });
  return res.data.listCompany;
};

export const getCompany = async (companyId: string): Promise<any> => {
  return apolloClient.query({
    query: GET_COMPANY,
    variables: {
      companyId,
    },
  });
};

export const updateCompany = async (
  payload: UpdateCompanyInput,
): Promise<any> => {
  return apolloClient.mutate({
    mutation: UPDATE_COMPANY,
    variables: {
      data: payload,
    },
  });
};

export const deleteCompany = async (userId: string): Promise<any> => {
  return apolloClient.mutate({
    mutation: DELETE_USER,
    variables: {
      id: userId,
    },
  });
};

export const listCompanyDepartment = async ({
  companyId,
  pagination,
  orderBy,
}): Promise<any> => {
  return apolloClient.query({
    query: LIST_COMPANY_DEPARTMENT,
    variables: {
      where: {
        companyId: {
          equals: companyId,
        },
      },
      pagination: {
        page: pagination?.page || 1,
        perPage: DEFAULT_PER_PAGE,
      },
      orderBy,
    },
  });
};

export const createCompanyDepartment = async (
  payload: MutationCreateCompanyDepartmentArgs,
): Promise<any> => {
  return apolloClient.mutate({
    mutation: CREATE_DEPARTMENT,
    variables: {
      ...payload,
    },
  });
};

export const updateCompanyDepartment = async (
  payload: UpdateCompanyDepartmentInput,
): Promise<any> => {
  return apolloClient.mutate({
    mutation: UPDATE_DEPARTMENT,
    variables: {
      data: payload,
    },
  });
};

export const deleteCompanyDepartment = async (id: string): Promise<any> => {
  return apolloClient.mutate({
    mutation: DELETE_DEPARTMENT,
    variables: {
      id,
    },
  });
};

export const getCompanyDepartment = async (id: string): Promise<any> => {
  return apolloClient.query({
    query: GET_COMPANY_DEPARTMENT,
    variables: {
      id,
    },
  });
};

export const filterListCompany = async ({
  where,
}: {
  where: CompanyWhereInput;
}): Promise<any> => {
  const res = await apolloClient.query({
    query: FILTER_LIST_COMPANY,
    variables: {
      where,
    },
  });

  return res.data.listCompany;
};

export const updateProfile = async (payload): Promise<any> => {
  return apolloClient.mutate({
    mutation: UPDATE_PROFILE,
    variables: {
      data: {
        ...payload,
      },
    },
  });
};

export const changeEmail = async (payload: {
  newEmail: string;
  password: string;
}): Promise<any> => {
  return apolloClient.mutate({
    mutation: CHANGE_EMAIL,
    variables: {
      ...payload,
    },
  });
};

export const filterListServiceTarget = async ({
  where,
  cursor,
  orderBy,
  pagination,
}: {
  where?: ServiceTargetWhereInput;
  orderBy?: ServiceTargetOrderByInput;
  cursor?: ServiceTargetCursorInput;
  pagination?: PaginationInput;
}): Promise<any> => {
  return apolloClient.query({
    query: FILTER_LIST_SERVICE_TARGET,
    variables: {
      where,
      orderBy,
      cursor,
      pagination,
    },
  });
};

export const changePassword = async (payload: {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}): Promise<any> => {
  return apolloClient.mutate({
    mutation: CHANGE_PASSWORD,
    variables: {
      ...payload,
    },
  });
};

export const deleteServiceTarget = async (id: string): Promise<any> => {
  return apolloClient.mutate({
    mutation: DELETE_SERVICE_TARGET,
    variables: {
      id,
    },
  });
};

export const createServiceTarget = async (
  payload: ServiceTargetInput,
): Promise<any> => {
  return apolloClient.mutate({
    mutation: CREATE_SERVICE_TARGET,
    variables: {
      data: payload,
    },
  });
};

export const detailServiceTarget = async (id: string): Promise<any> => {
  return apolloClient.query({
    query: DETAIL_SERVICE_TARGET,
    variables: {
      id,
    },
  });
};

export const updateServiceTarget = async (
  payload: ServiceTargetInput,
): Promise<any> => {
  return apolloClient.mutate({
    mutation: UPDATE_SERVICE_TARGET,
    variables: {
      data: payload,
    },
  });
};
